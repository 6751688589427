import React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { ADD_TO_WALLET_MUTATION } from '../../../../gql/Mutations'
import { eurosToCents } from '../../../../utils/Money'
import { TopupWalletByProfileAndWalletTypeModal } from './TopupWalletByProfileAndWalletTypeModal'
import { useAuth } from '../../../providers/AuthProvider'
import Loader from '../../../elements/Loader'

const TopupWalletByProfileAndWalletTypeMutation = ({
  walletType,
  profile,
  onSuccess,
  onClose,
}) => {
  console.log('TopupWalletMutation - profile', profile)
  console.log('TopupWalletMutation - walletType', walletType)
  const { tenantId } = useAuth()
  const [addToWallet, { data, error, loading }] = useLazyQuery(
    ADD_TO_WALLET_MUTATION,
  )
  const success = data?.selectProfiles?.addToWallet

  if (success) onSuccess()

  if (error) console.error(error)

  return (
    <>
      {loading && <Loader />}
      <TopupWalletByProfileAndWalletTypeModal
        walletType={walletType.description}
        profile={profile}
        onSuccess={amount => {
          addToWallet({
            variables: {
              isEnabled: profile.enabled,
              tenant: tenantId,
              profileIds: [profile.id],
              merchantTypes: walletType.isDefinedWallet ? [] : [walletType.id],
              definedWallets: walletType.isDefinedWallet ? [walletType.id] : [],
              tags: [],
              amount: eurosToCents(amount),
            },
          })
        }}
        onClose={onClose}
      />
    </>
  )
}

export default TopupWalletByProfileAndWalletTypeMutation
