import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_DOCUMENT_MUTATION } from '../../../gql/Mutations'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DocumentTemplatesAutocomplete from './DocumentTemplatesAutocomplete'
import TextField from '@material-ui/core/TextField'
import { useAuth } from '../../providers/AuthProvider'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 200,
  },
})

export default function CreateDocumentModal({
  legalEntityId,
  onClose,
  onSuccess,
}) {
  const classes = useStyles()
  const { tenantId } = useAuth()
  const [createDocument] = useMutation(CREATE_DOCUMENT_MUTATION)
  const [formState, setFormState] = React.useState({
    ownerId: tenantId,
    markup: '',
    parties: [],
  })
  const [formErrors, setFormErrors] = React.useState({})
  const [template, setTemplate] = React.useState(null)
  const [templateValues, setTemplateValues] = React.useState([])
  useEffect(() => {
    if (template) {
      setFormState(state => ({
        ...state,
        displayName: template.displayName,
        markup: template.markup,
        parties: template.parties.map(party => ({ displayName: party })),
      }))
      setTemplateValues(
        template.variables.map(variable => ({ ...variable, value: '' })),
      )
    } else {
      setFormState(state => ({
        ...state,
        displayName: '',
        markup: '',
        parties: [],
      }))
      setTemplateValues([])
    }
  }, [template])

  const isFormValid = () => {
    const { markup } = formState
    const _errors = []
    if (!markup) _errors['markup'] = 'Šis lauks ir obligāts'

    if (templateValues) {
      templateValues.forEach(v =>
        v?.value?.length > 0
          ? false
          : (_errors[`variable_${v.key}`] = 'Šis lauks ir obligāts'),
      )
    }

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    //prepare data
    let markup = formState.markup
    templateValues.forEach(v => {
      markup = markup.replace(v.key, v.value)
    })
    const data = {
      input: {
        ...formState,
        markup,
        tags: [legalEntityId.toString()],
      },
    }
    console.log('createDocument', data)
    createDocument({ variables: data })
      .then(id => onSuccess())
      .catch(e => console.error(e))
  }

  return (
    <Dialog fullWidth open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              JAUNS DOKUMENTS
            </Box>
          </DialogTitle>
          <DialogContent>
            <DocumentTemplatesAutocomplete
              onChange={template => setTemplate(template)}
              showError={formErrors['markup']}
            />
            {templateValues.map((v, index) => (
              <TextField
                key={index}
                variant="outlined"
                margin="dense"
                label={v.displayName}
                autoComplete="off"
                fullWidth
                value={v.value}
                helperText={v.description}
                onChange={({ target: { value } }) =>
                  setTemplateValues(
                    templateValues.map((_v, i) =>
                      i === index ? { ..._v, value: value } : _v,
                    ),
                  )
                }
                error={Boolean(formErrors[`variable_${v.key}`])}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Box mt={1} mr={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                Izveidot
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
