export const debounce = (fn, delay = 500) => {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const sortAlphabetically = (prev, next, fieldName) => {
  return prev[fieldName].localeCompare(next[fieldName], 'en', {
    sensitivity: 'base',
    ignorePunctuation: true,
  })
}
