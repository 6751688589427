import React, { forwardRef, useImperativeHandle } from 'react'
import MaterialTable from 'material-table'

function CustomizedMaterialTable(
  { options, components, localization, loading, pageSize, ...props },
  ref,
) {
  const tableRef = React.createRef()

  useImperativeHandle(ref, () => ({
    reload: () => {
      tableRef.current && tableRef.current.onQueryChange()
    },
  }))

  return (
    <MaterialTable
      {...props}
      tableRef={tableRef}
      options={{
        filtering: false,
        search: false,
        pageSize: pageSize || 10,
        actionsColumnIndex: props.columns.length,
        ...options,
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'rindas',
          labelDisplayedRows: `{from}-{to} no {count}`,
          firstTooltip: 'Pirmā lapa',
          nextTooltip: 'Nākamā lapa',
          previousTooltip: 'Iepriekšējā lapa',
          lastTooltip: 'Pēdējā lapa',
        },
        body: {
          emptyDataSourceMessage: !loading ? 'Nav datu' : '',
        },
        header: {
          actions: '',
        },
        ...localization,
      }}
      components={{
        // hide toolbar & groupbar by default
        Toolbar: () => null,
        Groupbar: () => null,
        ...components,
      }}
    />
  )
}

export default forwardRef(CustomizedMaterialTable)
