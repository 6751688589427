import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import SnackbarSuccess from '../../elements/SnackbarSuccess'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import AddEditProfileModal from './AddEditProfileModal'
import TopupWalletByProfileAndWalletTypeMutation from './topup-wallets/TopupWalletByProfileAndWalletTypeMutation'
import Loader from '../../elements/Loader'
import {
  GET_WALLETS_AND_LEGAL_ENTITIES_QUERY,
  LIST_PROFILES,
} from '../../../gql/Queries'
import {
  normalizeProfiles,
  processWalletData,
} from '../../../utils/ProfilesTable'
import TopupWalletsByTagsMutation from './topup-wallets/TopupWalletsByTagsMutation'
import { useAuth } from '../../providers/AuthProvider'
import { TagsAutocomplete } from '../../elements/TagsAutocomplete'
import { CompaniesAutocomplete } from '../../elements/CompaniesAutocomplete'
import { client } from '../../providers/ApolloProvider'
import { saveAs } from 'file-saver'
import { DownloadButton } from '../../elements/DownloadButton'
import { SearchInput } from '../../elements/SearchInput'

const { REACT_APP_END_POINT } = process.env

const useStyles = makeStyles({
  table: {
    marginTop: 8,
  },
  filterWrapper: {
    marginTop: 12,
  },
  tags: {
    marginLeft: 14,
    minWidth: 250,
  },
})

let lastListRequestId = 0

const tableRef = React.createRef()

export default function StaffIncentives() {
  const classes = useStyles()
  const { tenantId, token } = useAuth()

  const [multiContains, setMultiContains] = useState('')
  const [reload, setReload] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [loadingList, setLoadingList] = useState(false)
  const [companies, setCompanies] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [loadingReport, setLoadingReport] = useState(false)
  const [columns, setColumns] = useState([])
  const [showDisabledProfiles, setShowDisabledProfiles] = useState(false)
  const [showAddEditUserModal, setShowAddEditUserModal] = useState(false)
  const [showTopupWalletsModal, setShowTopupWalletsModal] = useState(false)
  const [showTopupWalletByProfileModal, setShowTopupWalletByProfileModal] =
    useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState()
  const [selectedWalletType, setSelectedWalletType] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [walletTypes, setWalletTypes] = useState([])

  useEffect(() => {
    reloadList(true)
  }, [showDisabledProfiles, multiContains, selectedTags, selectedCompanies])

  const reloadList = (hardReload = false) => {
    if (hardReload) {
      setReload(true)
    }

    tableRef.current && tableRef.current.reload()
  }

  const { loading } = useQuery(GET_WALLETS_AND_LEGAL_ENTITIES_QUERY, {
    variables: {
      tenant: tenantId,
      tenantLong: tenantId,
    },
    onCompleted: data => {
      if (data?.getTenantById) {
        const [combinedWallets, columns] = processWalletData(
          data,
          topupWalletByProfile,
        )

        setColumns(columns)
        setWalletTypes(combinedWallets)
        setCompanies(data?.getTenantById?.legalEntities || [])
      }
    },
  })

  const onRowEdit = (_, { tableData, ...profile }) => {
    setSelectedProfile(profile)
    setShowAddEditUserModal(true)
  }

  const onAddEditProfileSuccess = () => {
    setShowSuccessMsg(true)
    setShowAddEditUserModal(false)
    setSelectedProfile(null)
    reloadList()
  }

  const topupWalletByProfile = ({ tableData, ...profile }, walletType) => {
    setSelectedProfile(profile)
    setSelectedWalletType(walletType)
    setShowTopupWalletByProfileModal(true)
  }

  const downloadReport = () => {
    setLoadingReport(true)

    let url = `${REACT_APP_END_POINT}/rest/listProfiles.xlsx?tenant=${tenantId}&enabled=${!showDisabledProfiles}`

    if (selectedTags && selectedTags.length) {
      url += `&tags=${selectedTags.join(',')}`
    }

    if (selectedCompanies && selectedCompanies.length) {
      url += `&employer=${selectedCompanies
        .map(company => company.id)
        .join(',')}`
    }

    if (multiContains) {
      url += `&multi_contains=${multiContains}`
    }

    const requestConfig = {
      method: 'GET',
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    }

    fetch(url, requestConfig)
      .then(response => response.blob())
      .then(blob => saveAs(blob, 'labumi_lietotaji.xlsx'))
      .finally(() => setLoadingReport(false))
  }

  const fetchProfilesList = query => {
    setReload(false)
    setLoadingList(true)
    setPageSize(query.pageSize)

    const requestId = Math.floor(Math.random() * 1e8)

    lastListRequestId = requestId

    return new Promise(resolve => {
      const seek = reload ? 0 : query.page * query.pageSize
      const page = reload ? 0 : query.page
      const orderBy = query.orderBy
        ? query.orderBy.orderByKey || query.orderBy.field
        : 'id'
      const orderDesc = query.orderDirection === 'desc'

      client
        .query({
          query: LIST_PROFILES,
          fetchPolicy: 'network-only',
          variables: {
            tenant: tenantId,
            tags: selectedTags,
            limit: query.pageSize,
            enabled: !showDisabledProfiles,
            employer: selectedCompanies
              ? selectedCompanies.map(company => company.id)
              : [],
            seek,
            orderBy,
            orderDesc,
            multiContains,
          },
        })
        .then(resp => {
          /*
           * as apollo client does not provide ability to cancel pending requests
           * each request to fetch user's list get assigned unique ID
           * and only the last request gets used, the rest are discarded
           */
          if (requestId !== lastListRequestId) {
            return
          }

          const data = resp.data.listProfiles.list.map(normalizeProfiles)

          resolve({
            data,
            page,
            totalCount: resp.data.listProfiles.totalCount,
          })
        })
        .finally(() => {
          setLoadingList(false)
        })
    })
  }

  return (
    <>
      <SnackbarSuccess
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      />
      {showAddEditUserModal && (
        <AddEditProfileModal
          profileId={selectedProfile?.id}
          onSuccess={onAddEditProfileSuccess}
          onClose={() => setShowAddEditUserModal(false)}
          walletTypes={walletTypes}
          profileIdNumber={selectedProfile?.profileIdNumber}
        />
      )}
      {showTopupWalletsModal && (
        <TopupWalletsByTagsMutation
          walletTypes={walletTypes}
          onSuccess={() => {
            setShowSuccessMsg(true)
            setShowTopupWalletsModal(false)
            reloadList()
          }}
          onClose={() => setShowTopupWalletsModal(false)}
        />
      )}
      {showTopupWalletByProfileModal && selectedWalletType && selectedProfile && (
        <TopupWalletByProfileAndWalletTypeMutation
          walletType={selectedWalletType}
          profile={selectedProfile}
          onSuccess={() => {
            setShowTopupWalletByProfileModal(false)
            setSelectedProfile(null)
            setSelectedWalletType(null)
            setShowSuccessMsg(true)
            reloadList()
          }}
          onClose={() => setShowTopupWalletByProfileModal(false)}
        />
      )}
      <Box
        className={classes.filterWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Box display="flex" alignItems="center">
            <SearchInput
              onChangeHandler={val => setMultiContains(val)}
              label="Meklēt"
            />
            <TagsAutocomplete
              className={classes.tags}
              variant="standard"
              onChange={(_, value) => setSelectedTags(value)}
              value={selectedTags}
            />
            <CompaniesAutocomplete
              className={classes.tags}
              companies={companies}
              variant="standard"
              onChange={(_, value) => setSelectedCompanies(value)}
              value={selectedCompanies}
            />
          </Box>
          <Box display="flex" style={{ minHeight: 45 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDisabledProfiles}
                  color="primary"
                  onChange={(_, checked) => setShowDisabledProfiles(checked)}
                />
              }
              label="Rādīt deaktivizētus lietotājus"
            />
            <DownloadButton
              onClickHandler={downloadReport}
              loading={loadingReport}
            />
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ marginRight: 16 }}
            onClick={() => {
              setSelectedProfile(null)
              setShowAddEditUserModal(true)
            }}
          >
            Pievienot lietotāju
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowTopupWalletsModal(true)}
          >
            Papildināt maciņus
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Loader />
      ) : (
        <Box my={4}>
          <CustomizedMaterialTable
            ref={tableRef}
            columns={columns}
            pageSize={pageSize}
            data={fetchProfilesList}
            loading={loadingList}
            options={{
              rowStyle: ({ enabled }) => ({
                color: !enabled ? '#bbb' : '#000',
              }),
            }}
            actions={[
              {
                icon: 'edit',
                onClick: onRowEdit,
              },
            ]}
          />
        </Box>
      )}
    </>
  )
}
