import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import lvLocale from 'date-fns/locale/lv'

export default function DatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lvLocale}>
      <KeyboardDatePicker
        inputVariant="outlined"
        invalidDateMessage="date not valid"
        disableToolbar
        variant="inline"
        format="dd.MM.yyyy"
        margin="dense"
        inputProps={{ readOnly: true }}
        autoOk
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
