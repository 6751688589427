import { gql } from 'apollo-boost'

export const AUTHENTICATION_MUTATION = gql`
  mutation Authentication($input: CredentialsInput) {
    authentication {
      checkCredentials(input: $input) {
        token
        usernameId
        params {
          key
          value
        }
      }
    }
  }
`

export const PROFILE_MUTATION = gql`
  mutation UpdateProfile(
    $id: String!
    $tenant: Int!
    $input: SimpleProfileInput!
  ) {
    updateProfile(id: $id, tenant: $tenant, input: $input) {
      id
    }
  }
`

export const NEW_PROFILE_MUTATION = gql`
  mutation AddProfile($tenant: Int!, $input: SimpleProfileInput!) {
    addProfile(tenant: $tenant, input: $input) {
      id
    }
  }
`

export const CREATE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation CreateDocumentTemplate($input: DocumentTemplateInput!) {
    createDocumentTemplate(input: $input) {
      displayName
    }
  }
`

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: DocumentInput!) {
    createDocument(input: $input) {
      displayName
    }
  }
`

export const ADD_TO_WALLET_MUTATION = gql`
  query AddToWallet(
    $isEnabled: Boolean
    $tenant: Int
    $profileIds: [String!]
    $merchantTypes: [Int!]!
    $tags: [String!]
    $amount: Int!
    $definedWallets: [Long!]
    $createWallet: Boolean
  ) {
    selectProfiles(
      tenant: $tenant
      profileIds: $profileIds
      tags: $tags
      isEnabled: $isEnabled
    ) {
      addToWallet(
        amount: $amount
        merchantTypes: $merchantTypes
        definedWallets: $definedWallets
        createWallet: $createWallet
      )
    }
  }
`

export const SET_WALLET_MUTATION = gql`
  query SetWallet(
    $tenant: Int
    $profileIds: [String!]
    $merchantTypes: [Int!]!
    $tags: [String!]
    $amount: Int!
    $definedWallets: [Long!]
    $createWallet: Boolean
  ) {
    selectProfiles(tenant: $tenant, profileIds: $profileIds, tags: $tags) {
      setWallet(
        amount: $amount
        merchantTypes: $merchantTypes
        definedWallets: $definedWallets
        createWallet: $createWallet
      )
    }
  }
`

export const UPDATE_CURRENT_USER_PARAMETERS_MUTATION = gql`
  mutation updateCurrentUser(
    $username: String!
    $input: UpdateUserParameterInput!
  ) {
    credentials {
      updateParameter(username: $username, input: $input) {
        key
        value
        isEditable
      }
    }
  }
`

export const CHANGE_CURRENT_USER_PASSWORD_MUTATION = gql`
  mutation changeCurrentUserPassword($input: CredentialsInput!) {
    credentials {
      changePassword(input: $input)
    }
  }
`

export const ADD_TAG_MUTATION = gql`
  mutation addTag($input: TenantTagInput!) {
    tenantTags {
      add(input: $input) {
        tag
      }
    }
  }
`

export const REMOVE_TAG_MUTATION = gql`
  mutation removeTag($input: TenantTagInput!) {
    tenantTags {
      delete(input: $input, cascade: true)
    }
  }
`

export const CREATE_UPDATE_WALLET_POLICY_MUTATION = gql`
  mutation createNewWalletUpdatePolicy($input: WalletUpdatePolicyInput!) {
    walletUpdatePolicy {
      createWalletUpdatePolicy(input: $input) {
        id
      }
    }
  }
`

export const REMOVE_UPDATE_WALLET_POLICY_MUTATION = gql`
  mutation deleteWalletUpdatePolicy($id: Int!) {
    walletUpdatePolicy {
      removeWalletUpdatePolicy(id: $id)
    }
  }
`

export const SCHEDULE_USER_DEACTIVATION = gql`
  mutation UserDeactivationSchedule($input: UserDeactivationScheduleInput!) {
    userDeactivationSchedule {
      create(input: $input) {
        id
        profileId
        scheduledDate
        performedTime
        performed
      }
    }
  }
`

export const DELETE_USER_DEACTIVATION_SCHEDULE = gql`
  mutation deleteUserDeactivationSchedule($id: Int!) {
    userDeactivationSchedule {
      delete(id: $id)
    }
  }
`
