import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_DOCUMENT_TEMPLATE_MUTATION } from '../../../gql/Mutations'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useAuth } from '../../providers/AuthProvider'

const useStyles = makeStyles({
  dialog: {
    zIndex: 5000,
  },
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 200,
  },
})

export default function CreateDocumentTemplateModal({ onClose, onSuccess }) {
  const { tenantId } = useAuth()
  const classes = useStyles()
  const [createDocumentTemplate] = useMutation(
    CREATE_DOCUMENT_TEMPLATE_MUTATION,
  )

  const getMarkupVariables = markup => {
    if (markup) {
      const keys = markup.match(/#\{([^}]+)\}/g)
      return keys
        ? keys.map(key => ({
            key,
            displayName: '',
            description: '',
          }))
        : []
    }
  }

  const initialMarkup =
    '<h2>Šeit jāievieto HTML kods ar mainīgiem, piem. #{nomainimani}!</h2>'
  const [formState, setFormState] = React.useState({
    ownerId: tenantId,
    displayName: '',
    markup: initialMarkup,
    variables: getMarkupVariables(initialMarkup),
    parties: ['Darba devēja vārdā', 'Darbinieki'],
  })
  const [formErrors, setFormErrors] = React.useState({})
  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value })

  const setTemplateMarkup = markup => {
    if (markup) {
      setFormState({
        ...formState,
        markup,
        variables: getMarkupVariables(markup),
      })
    }
  }

  const onTemplateMarkupChange = event => {
    setTemplateMarkup(event.target.value)
  }

  const isFormValid = () => {
    const { displayName, markup, variables, parties } = formState
    const _errors = []

    if (!displayName) _errors['displayName'] = 'Šis lauks ir obligāts'
    if (!markup) _errors['markup'] = 'Šis lauks ir obligāts'
    if (variables) {
      variables.forEach(v =>
        v.displayName?.length > 0
          ? false
          : (_errors[`variable_${v.key}`] = 'Šis lauks ir obligāts'),
      )
    }
    if (parties) {
      parties.forEach((p, i) =>
        p?.length > 0
          ? false
          : (_errors[`party_${i}`] = 'Šis lauks ir obligāts'),
      )
    }
    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    const data = { input: { ...formState } }
    console.log('createDocumentTemplate', data)
    createDocumentTemplate({ variables: data })
      .then(id => onSuccess())
      .catch(e => console.error(e))
  }

  return (
    <Dialog fullWidth open onClose={onClose} className={classes.dialog}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              JAUNA DOKUMENTU SAGATAVE
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="dense"
              label="Nosaukums"
              fullWidth
              name="displayName"
              autoComplete="off"
              value={formState.displayName}
              onChange={onFieldChange}
              helperText={formErrors.displayName}
              error={Boolean(formErrors.displayName)}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Dokumenta saturs"
              fullWidth
              name="markup"
              autoComplete="off"
              value={formState.markup}
              onChange={onTemplateMarkupChange}
              helperText={formErrors.markup}
              error={Boolean(formErrors.markup)}
            />
            {formState.variables.map((variable, index) => (
              <Box key={index} display="flex" alignItems="center">
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Mainīgais`}
                  autoComplete="off"
                  value={variable.key}
                  disabled
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Nosaukums`}
                  autoComplete="off"
                  value={variable.displayName}
                  onChange={({ target: { value } }) =>
                    setFormState({
                      ...formState,
                      variables: formState.variables.map((p, i) =>
                        i === index ? { ...p, displayName: value } : p,
                      ),
                    })
                  }
                  error={Boolean(formErrors[`variable_${variable.key}`])}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Apraksts`}
                  autoComplete="off"
                  value={variable.description}
                  onChange={({ target: { value } }) =>
                    setFormState({
                      ...formState,
                      variables: formState.variables.map((p, i) =>
                        i === index ? { ...p, description: value } : p,
                      ),
                    })
                  }
                />
              </Box>
            ))}
            {formState.parties.map((party, index) => (
              <Box key={index} display="flex" alignItems="center">
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Paraksttiesīgās puses nosaukums`}
                  fullWidth
                  autoComplete="off"
                  value={formState.parties[index]}
                  onChange={({ target: { value } }) =>
                    setFormState({
                      ...formState,
                      parties: formState.parties.map((p, i) =>
                        i === index ? value : p,
                      ),
                    })
                  }
                  helperText={formErrors[`party_${index}`]}
                  error={Boolean(formErrors[`party_${index}`])}
                />
                {index === 0 ? (
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setFormState({
                        ...formState,
                        parties: [...formState.parties, ''],
                      })
                    }
                  >
                    <Tooltip title="Pievienot paraksttiesīgo pusi">
                      <ControlPointIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setFormState({
                        ...formState,
                        parties: formState.parties.filter(
                          (_, i) => i !== index,
                        ),
                      })
                    }
                  >
                    <Tooltip title="Dzēst paraksttiesīgo pusi">
                      <HighlightOffIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Box mt={1} mr={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                Izveidot
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
