import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { LIST_USER_DEACTIVATION_SCHEDULE } from '../../gql/Queries'
import {
  DELETE_USER_DEACTIVATION_SCHEDULE,
  SCHEDULE_USER_DEACTIVATION,
} from '../../gql/Mutations'
import Tooltip from '@material-ui/core/Tooltip'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  HighlightOff,
  Check,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import DatePicker from './DatePicker'
import Button from '@material-ui/core/Button'
import SnackbarSuccess from './SnackbarSuccess'

const useStyles = makeStyles({
  title: {
    color: 'grey',
    fontSize: 12,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  plannerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

export default function ScheduleUserDeactivationSection({ profileIdNumber }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [pickedDate, setPickedDate] = useState(null)
  const [warning, setWarning] = useState(null)
  const [snackBarMsg, setSnackBarMsg] = useState(null)

  const { data, loading, error, refetch } = useQuery(
    LIST_USER_DEACTIVATION_SCHEDULE,
    {
      variables: {
        profileId: profileIdNumber,
      },
    },
  )

  const [deleteScheduledDeactivation] = useMutation(
    DELETE_USER_DEACTIVATION_SCHEDULE,
  )

  const [scheduleDeactivation] = useMutation(SCHEDULE_USER_DEACTIVATION)

  useEffect(() => {
    const _rows = data?.userDeactivationSchedule?.byProfile
      .filter(it => !it.performed)
      .map(it => {
        return {
          id: it.id,
          date: it.scheduledDate,
        }
      })
    setRows(_rows || [])
  }, [data, error])

  const onDelete = id => {
    deleteScheduledDeactivation({
      variables: {
        id,
      },
    })
      .then(() => {
        refetch()
        setSnackBarMsg('Izdzēsts!')
      })
      .catch(err => {
        setWarning('Neizdevās izdzēst ieplānoto lietotāja deaktivizēšanu')
      })
  }

  const onConfirmDate = () => {
    scheduleDeactivation({
      variables: {
        input: {
          profileId: profileIdNumber,
          scheduledDate: pickedDate && pickedDate.toISOString().split('T')[0],
        },
      },
    })
      .then(() => {
        refetch()
        setSnackBarMsg('Datums pievienots!')
      })
      .catch(err => {
        setWarning(
          'Neizdevās pievienot plānoto datumu lietotāja deaktivizēšanai',
        )
      })
      .finally(() => {
        setPickedDate(null)
      })
  }

  return (
    <Box>
      <SnackbarSuccess
        open={!!snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
        message={snackBarMsg}
      />
      <Box onClick={() => setIsOpen(!isOpen)} className={classes.titleWrapper}>
        <Box className={classes.title}>Lietotāja deaktivizēšanas plānošana</Box>
        {isOpen ? (
          <KeyboardArrowUp color="primary" />
        ) : (
          <KeyboardArrowDown color="primary" />
        )}
      </Box>
      {isOpen && (
        <>
          <Box style={{ fontSize: 14 }}>
            {loading && <Box>Sagatavo datus...</Box>}
            {!loading && rows.length > 0 && (
              <>
                <Box>
                  {rows.length === 1 ? 'Plānotais datums ' : 'Plānotie datumi '}
                  lietotāja deaktivizēšanai:
                </Box>
                {rows.map(({ id, date }) => {
                  return (
                    <Box ml={3} key={id} display="flex" alignItems="center">
                      <IconButton
                        style={{ padding: 4 }}
                        color="secondary"
                        onClick={() => onDelete(id)}
                      >
                        <Tooltip title="Atcelt plānoto deaktivizēšanu">
                          <HighlightOff />
                        </Tooltip>
                      </IconButton>
                      <Box>{date}</Box>
                    </Box>
                  )
                })}
              </>
            )}
          </Box>
          <Box fontSize={14}>Izvēlēties deaktivizēšanas datumu:</Box>
          <Box className={classes.plannerWrapper}>
            <DatePicker
              value={pickedDate}
              inputVariant="standard"
              minDate={tomorrow}
              placeholder="Izvēlies datumu"
              label=""
              onChange={date => {
                setWarning(null)
                setPickedDate(date || null)
              }}
            />
            {pickedDate && (
              <Button
                title="Apstiprināt deaktivizēšanas datumu"
                variant="contained"
                color="primary"
                startIcon={<Check />}
                style={{ marginLeft: 8 }}
                onClick={() => onConfirmDate()}
              >
                Apstiprināt
              </Button>
            )}
          </Box>
          {warning && (
            <Box fontSize={14} color="red">
              {warning}
              <IconButton
                style={{ padding: 4 }}
                color="secondary"
                onClick={() => setWarning(null)}
              >
                <Tooltip title="Nerādīt brīdinājumu">
                  <HighlightOff />
                </Tooltip>
              </IconButton>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
