import React from 'react'
import Header from '../elements/Header'
import Navigation from '../elements/Navigation'
import { Switch, Route } from 'react-router-dom'
import StaffIncentives from './staff-incentives/StaffIncentives'
import Box from '@material-ui/core/Box'
import Settings from './settings/Settings'
import Transactions from './Transactions'
import Documents from './documents/Documents'

export default function Main() {
  return (
    <Box px={8} mt={1}>
      <Header />
      <Navigation />
      <Switch>
        <Route exact path="/" component={StaffIncentives} />
        <Route exact path="/iestatijumi" component={Settings} />
        <Route exact path="/darijumi" component={Transactions} />
        <Route exact path="/dokumenti" component={Documents} />
        <Route exact path="/dokumenti/parakstit" component={Documents} />
      </Switch>
    </Box>
  )
}
