import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LIST_DOCUMENT_SIGN_OPTIONS } from '../../../gql/Queries'
import Box from '@material-ui/core/Box'
import Loader from '../../elements/Loader'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  option: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'white',
    marginRight: '1em',
  },
})

export default function SignDocumentOptions() {
  const classes = useStyles()
  const { loading, data } = useQuery(LIST_DOCUMENT_SIGN_OPTIONS)
  const { guid } = useParams()
  return loading ? (
    <Loader />
  ) : data?.listDocumentSignOptions?.length > 0 ? (
    <Box width="100%" height="100%">
      {data.listDocumentSignOptions.map((option, index) => (
        <a
          key={`${option.kind}${index}`}
          href={`${process.env.REACT_APP_END_POINT}/rest/documentauth/init?guid=${guid}&kind=${option.kind}`}
        >
          <img
            className={classes.option}
            src={`data:image/png;base64, ${option.logo}`}
            alt={option.displayName}
          />
        </a>
      ))}
    </Box>
  ) : (
    <h2 className={classes.noOptions}>Autorizācija šobrīd nav iespējama</h2>
  )
}
