import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CustomizedMaterialTable from '../elements/CustomizedMaterialTable'
import { getFirstLastDayOfMonth } from '../../utils/Date'
import DatePicker from '../elements/DatePicker'
import { MerchantTypesAutocomplete } from '../elements/MerchantTypesAutocomplete'
import { MerchantsAutocomplete } from '../elements/MerchantsAutocomplete'
import { useAuth } from '../providers/AuthProvider'
import { DownloadButton } from '../elements/DownloadButton'
import { saveAs } from 'file-saver'
import { client } from '../providers/ApolloProvider'
import { LIST_TRANSACTIONS_QUERY } from '../../gql/Queries'
import { SearchInput } from '../elements/SearchInput'
import {
  normalizeTransaction,
  TransactionOrderBy,
  transactionColumns,
} from '../../utils/TransactionTable'

const { REACT_APP_END_POINT } = process.env

const useStyles = makeStyles(theme => ({
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  date: {
    width: 250,
  },
}))

let lastListRequestId = 0

const tableRef = React.createRef()

export default function Transactions() {
  const { tenantId, token } = useAuth()
  const classes = useStyles()
  const { lastDayCurrentMonth, firstDayCurrentMonth } = getFirstLastDayOfMonth()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedMerchantType, setSelectedMerchantType] = useState(null)
  const [selectedMerchant, setSelectedMerchant] = useState(null)
  const [selectedDateFrom, setSelectedDateFrom] = useState(firstDayCurrentMonth)
  const [selectedDateTo, setSelectedDateTo] = useState(lastDayCurrentMonth)
  const [loadingReport, setLoadingReport] = useState(false)
  const [reload, setReload] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [loadingTransactions, setLoadingTransactions] = useState(false)

  useEffect(() => {
    setReload(true)
    tableRef.current && tableRef.current.reload()
  }, [
    selectedDateFrom,
    selectedDateTo,
    selectedMerchant,
    selectedMerchantType,
    phoneNumber,
  ])

  const downloadReport = () => {
    setLoadingReport(true)

    let url = `${REACT_APP_END_POINT}/rest/listTransactions.xlsx?tenant=${tenantId}`

    if (selectedDateFrom) {
      url += `&time_from=${selectedDateFrom.getTime()}`
    }

    if (selectedDateTo) {
      url += `&time_to=${selectedDateTo.getTime()}`
    }

    if (selectedMerchant) {
      url += `&merchant=${selectedMerchant.id}`
    }

    if (selectedMerchantType) {
      url += `&merchant_type=${selectedMerchantType.id}`
    }

    if (phoneNumber) {
      url += `&phone_number_contains=${phoneNumber}`
    }

    const requestConfig = {
      method: 'GET',
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    }

    fetch(url, requestConfig)
      .then(response => response.blob())
      .then(blob => saveAs(blob, 'labumi_darijumi.xlsx'))
      .finally(() => setLoadingReport(false))
  }

  const fetchTransactions = query => {
    setReload(false)
    setLoadingTransactions(true)
    setPageSize(query.pageSize)

    const requestId = Math.floor(Math.random() * 1e8)

    lastListRequestId = requestId

    return new Promise(resolve => {
      const skip = reload ? 0 : query.page * query.pageSize
      const page = reload ? 0 : query.page
      const orderBy = query.orderBy
        ? query.orderBy.sortId
        : TransactionOrderBy.TX_ID
      const orderDesc = query.orderDirection === 'desc'

      client
        .query({
          query: LIST_TRANSACTIONS_QUERY,
          variables: {
            tenant: tenantId,
            timeFrom: selectedDateFrom?.getTime(),
            timeTo: selectedDateTo?.getTime(),
            merchant: selectedMerchant?.id,
            merchantType: selectedMerchantType?.id,
            skip,
            limit: query.pageSize,
            orderBy,
            orderDesc,
            phoneNumberContains: phoneNumber,
          },
        })
        .then(resp => {
          /*
           * as apollo client does not provide ability to cancel pending requests
           * each request to fetch user's list get assigned unique ID
           * and only the last request gets used, the rest are discarded
           */
          if (requestId !== lastListRequestId) {
            return
          }

          const data = resp.data.listTransactions.list.map(normalizeTransaction)

          resolve({
            data,
            page,
            totalCount: resp.data.listTransactions.totalCount,
          })
        })
        .finally(() => {
          setLoadingTransactions(false)
        })
    })
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Box>
            <DatePicker
              className={classes.date}
              value={selectedDateFrom}
              inputVariant="standard"
              label="No"
              onChange={date => {
                date && date.setHours(0, 0, 0, 0)
                setSelectedDateFrom(date)
              }}
            />
          </Box>
          <Box ml={2}>
            <DatePicker
              className={classes.date}
              value={selectedDateTo}
              inputVariant="standard"
              label="Līdz"
              onChange={date => {
                date && date.setHours(23, 59, 59, 999)
                setSelectedDateTo(date)
              }}
            />
          </Box>
        </Box>
        <DownloadButton
          onClickHandler={downloadReport}
          loading={loadingReport}
        />
      </Box>
      <Box mt={1} mb={3} display="flex" alignItems="center">
        <SearchInput
          onChangeHandler={val => setPhoneNumber(val)}
          label="Vārds / Uzvārds / Telefona nr."
          width={250}
        />
        <Box ml={2} width={250}>
          <MerchantTypesAutocomplete
            onChange={(_, value) => setSelectedMerchantType(value)}
            value={selectedMerchantType}
          />
        </Box>
        <Box ml={2} width={250}>
          <MerchantsAutocomplete
            onChange={(_, value) => setSelectedMerchant(value)}
            value={selectedMerchant}
          />
        </Box>
      </Box>
      <CustomizedMaterialTable
        ref={tableRef}
        columns={transactionColumns}
        loading={loadingTransactions}
        data={fetchTransactions}
        pageSize={pageSize}
      />
    </>
  )
}
