import React from 'react'
import { GET_TENANT_LEGAL_ENTITIES_QUERY } from '../../../gql/Queries'
import { useQuery } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../../providers/AuthProvider'

const useStyles = makeStyles({
  autocomplete: {
    minWidth: 300,
  },
})

export default function LegalEntitiesAutocomplete({
  selectFirst,
  variant = 'standard',
  helperText,
  error,
  ...props
}) {
  const classes = useStyles()
  const [legalEntities, setLegalEntities] = React.useState([])

  const { tenantId } = useAuth()
  const { loading } = useQuery(GET_TENANT_LEGAL_ENTITIES_QUERY, {
    variables: {
      tenantId: tenantId,
    },
    //https://github.com/apollographql/react-apollo/issues/2177
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getTenantById) {
        setLegalEntities(data.getTenantById.legalEntities)
        // Select first by default
        if (selectFirst && data.getTenantById.legalEntities.length > 0)
          if (props.onChange)
            props.onChange(null, data.getTenantById.legalEntities[0])
      }
    },
  })

  return (
    <Autocomplete
      {...props}
      className={classes.autocomplete}
      options={legalEntities}
      getOptionLabel={t => t.name}
      loading={loading}
      getOptionSelected={(legalEntity, value) => legalEntity.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          margin="dense"
          label="Juridiskā persona"
          variant={variant}
          helperText={helperText}
          error={error}
          fullWidth
        />
      )}
      noOptionsText="Nav atbilstošu ierakstu"
    />
  )
}
