import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  AUTHENTICATION_MUTATION,
  CHANGE_CURRENT_USER_PASSWORD_MUTATION,
  UPDATE_CURRENT_USER_PARAMETERS_MUTATION,
} from '../../gql/Mutations'

const Context = React.createContext(null)

export function useAuth() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

const userJson = localStorage.getItem('user')
const userObj = userJson && JSON.parse(userJson)

export default function AuthProvider({ children }) {
  const [authenticate] = useMutation(AUTHENTICATION_MUTATION, {
    fetchPolicy: 'no-cache',
  })
  const [updateCurrentUserParameters] = useMutation(
    UPDATE_CURRENT_USER_PARAMETERS_MUTATION,
  )
  const [changeCurrentUserPassword] = useMutation(
    CHANGE_CURRENT_USER_PASSWORD_MUTATION,
  )
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  const [isAdmin, setIsAdmin] = React.useState(userObj?.isAdmin)
  const [username, setUsername] = React.useState(userObj?.username)
  const [firstName, setFirstName] = React.useState(userObj?.firstName)
  const [familyName, setFamilyName] = React.useState(userObj?.familyName)
  const [tenantId, setTenantId] = React.useState(userObj?.tenantId)

  const loadUserDataFromCache = () => {
    const userJson = localStorage.getItem('user')
    const userObj = userJson && JSON.parse(userJson)

    setIsAdmin(userObj?.isAdmin)
    setUsername(userObj?.username)
    setFirstName(userObj?.firstName)
    setFamilyName(userObj?.familyName)
    setTenantId(userObj?.tenantId)
  }

  const login = (username, password) => {
    return authenticate({
      variables: {
        input: {
          username,
          password,
        },
      },
    })
      .then(({ data }) => {
        if (data?.authentication?.checkCredentials) {
          const { token, params, usernameId } =
            data.authentication.checkCredentials
          const firstNameParam = params.find(p => p.key === 'firstName')
          const familyNameParam = params.find(p => p.key === 'familyName')
          const tenantIdParam = params.find(p => p.key === 'tenantId')
          const isAdminParam = params.find(p => p.key === 'isAdmin')

          const firstName = firstNameParam?.value
          const familyName = familyNameParam?.value
          const tenantId = Number(tenantIdParam?.value)
          const isAdmin = Boolean(isAdminParam?.value)

          localStorage.setItem('token', token)
          localStorage.setItem(
            'user',
            JSON.stringify({
              id: usernameId,
              username,
              firstName,
              familyName,
              tenantId,
              isAdmin,
            }),
          )

          setToken(token)
          setUsername(username)
          setIsAdmin(isAdmin)
          setTenantId(tenantId)
          setFirstName(firstName)
          setFamilyName(familyName)
        } else {
          throw Error('Nepareiz lietotāja vārds un/vai parole')
        }
      })
      .catch(() => {
        throw Error('Nepareiz lietotāja vārds un/vai parole')
      })
  }

  const logout = () => {
    localStorage.clear()
    setIsAdmin(null)
    setToken(null)
    setTenantId(null)
    setFamilyName(null)
    setFirstName(null)
    setUsername(null)
  }

  const updateUserInfo = params => {
    return Promise.all(
      params.map(({ key, value }) =>
        updateCurrentUserParameters({
          variables: {
            username: username,
            input: {
              key: key,
              value: value,
              isEditable: true,
            },
          },
        }).then(() => {
          const userJson = localStorage.getItem('user')
          const userObj = userJson && JSON.parse(userJson)
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userObj,
              [key]: value,
            }),
          )
        }),
      ),
    ).then(() => {
      loadUserDataFromCache()
    })
  }

  const changeUserPassword = password => {
    return changeCurrentUserPassword({
      variables: {
        input: {
          username,
          password,
        },
      },
    })
  }

  return (
    <Context.Provider
      value={{
        token,
        login,
        logout,
        updateUserInfo,
        changeUserPassword,
        firstName,
        familyName,
        tenantId,
        isAdmin,
        username,
      }}
    >
      {children}
    </Context.Provider>
  )
}
