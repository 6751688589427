import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ADD_TO_WALLET_MUTATION } from '../../../../gql/Mutations'
import { eurosToCents } from '../../../../utils/Money'
import TopupWalletsByTagsModal from './TopupWalletsByTagsModal'
import { useAuth } from '../../../providers/AuthProvider'

const TopupWalletsByTagsMutation = ({ onSuccess, onClose, walletTypes }) => {
  const { tenantId } = useAuth()
  const { refetch } = useQuery(ADD_TO_WALLET_MUTATION, {
    skip: true,
  })

  return (
    <TopupWalletsByTagsModal
      wallets={walletTypes}
      onSuccess={({ wallets: selectedWallets, tags, modifyAllUserWallets }) => {
        selectedWallets.forEach((w, index) => {
          refetch({
            isEnabled: true,
            tenant: tenantId,
            profileIds: [],
            merchantTypes: w.isDefinedWallet ? [] : [w.id],
            definedWallets: w.isDefinedWallet ? [w.id] : [],
            tags: tags,
            amount: eurosToCents(Number(w.amount || '0')),
            createWallet: modifyAllUserWallets,
          })
            .then(({ data, errors }) => {
              if (errors) {
                return
              }
              if (index === selectedWallets.length - 1 && data) {
                onSuccess()
              }
            })
            .catch(err => {
              console.error(err)
            })
        })
      }}
      onClose={onClose}
    />
  )
}

export default TopupWalletsByTagsMutation
