import React from 'react'
import Box from '@material-ui/core/Box'
import { LIST_DOCUMENT_TEMPLATES_QUERY } from '../../../gql/Queries'
import { useQuery } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useAuth } from '../../providers/AuthProvider'

export default function LegalDocumentTemplateSelect({ onChange, showError }) {
  const { tenantId } = useAuth()
  const { error, loading } = useQuery(LIST_DOCUMENT_TEMPLATES_QUERY, {
    variables: {
      ownerId: tenantId,
    },
    onCompleted: data => {
      setTemplates(data?.listDocumentTemplates)
    },
  })
  const [templates, setTemplates] = React.useState([])
  return (
    <Box>
      <Autocomplete
        options={templates}
        getOptionLabel={t => t.displayName}
        loading={loading}
        onChange={(event, newValue) => onChange(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            error={showError}
            label={'Dokumenta sagatave'}
            margin="dense"
            variant="outlined"
            fullWidth
          />
        )}
        error={error}
        noOptionsText={'Nav atbilstošu ierakstu'}
      />
    </Box>
  )
}
