import React from 'react'
import { LIST_DOCUMENTS_QUERY } from '../../../gql/Queries'
import { useLazyQuery } from '@apollo/react-hooks'
import Loader from '../../elements/Loader'
import Box from '@material-ui/core/Box'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import { makeStyles } from '@material-ui/core/styles'
import LegalEntitiesAutocomplete from './LegalEntitiesAutocomplete'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CreateDocumentModal from './CreateDocumentModal'
import SnackbarSuccess from '../../elements/SnackbarSuccess'
import CreateDocumentTemplateModal from './CreateDocumentTemplateModal'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PersonIcon from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'
import LinkIcon from '@material-ui/icons/Link'
import { formatDateTime } from '../../../utils/Date'
import { useAuth } from '../../providers/AuthProvider'
import { saveAs } from 'file-saver'

const useStyles = makeStyles({
  table: {
    marginTop: 8,
  },
  filterWrapper: {
    marginTop: 12,
  },
  search: {
    width: 340,
  },
  selectStatus: {
    minWidth: 230,
    marginLeft: 24,
  },
  pdf: {
    cursor: 'pointer',
  },
  signatureDetails: {
    margin: 16,
  },
  partySignatureDetails: {
    margin: 16,
  },
})

export default function Documents() {
  const classes = useStyles()
  const { tenantId, token } = useAuth()
  const [legalEntity, setLegalEntity] = React.useState(null)
  const [columns, setColumns] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [showCreateDocumentModal, setShowCreateDocumentModal] =
    React.useState(false)
  const [showCreateDocumentTemplateModal, setShowCreateDocumentTemplateModal] =
    React.useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false)
  const [documentLoading, setDocumentLoading] = React.useState(false)

  const openDocument = (guid, displayName) => {
    setDocumentLoading(true)
    fetch(`${process.env.REACT_APP_END_POINT}/rest/document.pdf?guid=${guid}`, {
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${displayName}.pdf`)
        setDocumentLoading(false)
      })
      .catch(error => {
        setDocumentLoading(false)
      })
  }

  const [fetchDocumentsQuery, { loading }] = useLazyQuery(
    LIST_DOCUMENTS_QUERY,
    {
      onCompleted: data => {
        const documents = data?.listDocuments
        if (documents) {
          setColumns([
            { field: 'displayName', title: 'Nosaukums' },
            {
              field: 'creationTime',
              title: 'Izveidots',
              render: ({ creationTime }) => formatDateTime(creationTime),
            },
            {
              field: 'id',
              title: 'Fails ar parakstiem',
              render: ({ guid, displayName }) => (
                <PictureAsPdfIcon
                  className={classes.pdf}
                  onClick={() => openDocument(guid, displayName)}
                />
              ),
            },
            {
              field: 'parties',
              title: 'URL parakstīšanai',
              render: ({ parties }) => {
                return parties
                  ? parties.map((p, index) => (
                      <Link
                        key={p.guid}
                        target="_blank"
                        to={`/signdocument/${p.guid}`}
                      >
                        <Box
                          mt={index ? 1 : 0}
                          mr={2}
                          display="flex"
                          alignItems="center"
                        >
                          <LinkIcon />
                          <Box ml={0.3}>{p.displayName}</Box>
                        </Box>
                      </Link>
                    ))
                  : null
              },
            },
            {
              field: 'parties',
              title: 'Paraksti',
              render: ({ parties }) => {
                return parties
                  ? parties.map((p, index) => (
                      <Box
                        key={index}
                        mt={index ? 1 : 0}
                        mr={2}
                        display="flex"
                        alignItems="center"
                      >
                        <PersonIcon />
                        <Box ml={0.3}>
                          {p.displayName}: {p.signatures.length}
                        </Box>
                      </Box>
                    ))
                  : null
              },
            },
          ])
          setRows(documents)
        }
      },
    },
  )

  React.useEffect(() => {
    if (legalEntity && !showCreateDocumentModal) {
      fetchDocumentsQuery({
        variables: {
          ownerId: tenantId,
          tags: [legalEntity.id.toString()],
        },
      })
    }
  }, [tenantId, legalEntity, showCreateDocumentModal, fetchDocumentsQuery])

  function onCreateDocumentSuccess() {
    setShowSuccessMessage(true)
    setShowCreateDocumentModal(false)
  }

  function onCreateTemplateSuccess() {
    setShowSuccessMessage(true)
    setShowCreateDocumentTemplateModal(false)
  }

  return (
    <>
      {(loading || documentLoading) && <Loader />}
      <SnackbarSuccess
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      />
      {showCreateDocumentTemplateModal && (
        <CreateDocumentTemplateModal
          onSuccess={onCreateTemplateSuccess}
          onClose={() => setShowCreateDocumentTemplateModal(false)}
        />
      )}
      {showCreateDocumentModal && legalEntity && (
        <CreateDocumentModal
          legalEntityId={legalEntity.id}
          onSuccess={onCreateDocumentSuccess}
          onClose={() => setShowCreateDocumentModal(false)}
        />
      )}
      <Box
        className={classes.filterWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <LegalEntitiesAutocomplete
            onChange={(_, value) => setLegalEntity(value)}
            value={legalEntity}
            selectFirst
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ marginRight: 16 }}
            onClick={() => setShowCreateDocumentTemplateModal(true)}
          >
            Izveidot jaunu sagatavi
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateDocumentModal(true)}
          >
            Izveidot jaunu dokumentu
          </Button>
        </Box>
      </Box>
      <Box my={4}>
        <CustomizedMaterialTable
          columns={columns}
          data={rows}
          detailPanel={[
            {
              tooltip: 'Parādīt parakstus',
              render: document => {
                return (
                  <Box className={classes.signatureDetails}>
                    {document.parties.map((party, index) => (
                      <Box
                        key={index}
                        ml={0.3}
                        className={classes.partySignatureDetails}
                      >
                        <p>{party.displayName}:</p>
                        <ul>
                          {party.signatures && party.signatures.length > 0
                            ? party.signatures.map(
                                (
                                  { firstName, lastName, personalCode },
                                  index,
                                ) => (
                                  <li key={index}>
                                    {firstName} {lastName} p.k.{personalCode}
                                  </li>
                                ),
                              )
                            : 'Nav parakstu'}
                        </ul>
                      </Box>
                    ))}
                  </Box>
                )
              },
            },
          ]}
        />
      </Box>
    </>
  )
}
