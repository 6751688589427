import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_DOCUMENT_SIGNATURE } from '../../../gql/Queries'
import Box from '@material-ui/core/Box'
import Loader from '../../elements/Loader'
import { makeStyles } from '@material-ui/core/styles'
import SignDocumentErrorMessage from './SignDocumentErrorMessage'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles({
  tick: {
    color: 'green',
    margin: '10px',
    width: '80px',
    height: '80px',
  },
  preview: {
    position: 'fixed',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '88%',
  },
  actions: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '12%',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  party: {
    width: '34%',
  },
  signature: {
    width: '66%',
  },
  optionsHeader: {
    marginBlockEnd: 0,
    marginBottom: 0,
  },
})

export default function SignDocumentSuccess() {
  const classes = useStyles()
  const { guid } = useParams()
  const { loading, error, data } = useQuery(GET_DOCUMENT_SIGNATURE, {
    variables: { guid },
  })

  const signatureExists = guid && !error && data?.getDocumentSignature
  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" height="100%">
      {signatureExists ? (
        <>
          <iframe
            title={'Dokumenta parakstīšāna'}
            className={classes.preview}
            src={`${process.env.REACT_APP_END_POINT}/rest/document.pdf?guid=${data.getDocumentSignature.documentParty.documentGuid}`}
          />
          <Box
            px={8}
            mt={1}
            display="flex"
            alignItems="top"
            alignContent="top"
            justifyContent="flex-start"
            className={classes.actions}
          >
            <Box className={classes.party}>
              <h3 className={classes.optionsHeader}>
                Jūs esat parakstījušies laukā:
              </h3>
              <h2>"{data.getDocumentSignature.documentParty.displayName}"</h2>
            </Box>
            <Box className={classes.options}>
              <Box>
                <CheckCircleOutlineIcon className={classes.tick} />
              </Box>
              <Box>
                <h3 className={classes.optionsHeader}>Jūsu paraksts:</h3>
                <h2>
                  {data.getDocumentSignature.firstName}{' '}
                  {data.getDocumentSignature.lastName}, p.k.{' '}
                  {data.getDocumentSignature.personalCode}
                </h2>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <SignDocumentErrorMessage message={'Nav izdevies atrast parakstu'} />
      )}
    </Box>
  )
}
