import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { useMutation, useQuery } from '@apollo/react-hooks'
import SnackbarSuccess from '../../../elements/SnackbarSuccess'
import CustomizedMaterialTable from '../../../elements/CustomizedMaterialTable'
import Loader from '../../../elements/Loader'
import { useAuth } from '../../../providers/AuthProvider'
import { LIST_WALLET_UPDATE_POLICIES } from '../../../../gql/Queries'
import AddEditRuleModal, {
  ANNUAL,
  MONTHLY,
  QUARTERLY,
  WEEKLY,
} from './AddEditRuleModal'
import { centsToEuros } from '../../../../utils/Money'
import { REMOVE_UPDATE_WALLET_POLICY_MUTATION } from '../../../../gql/Mutations'
import ConfirmModal from '../../../elements/ConfirmModal'

const useStyles = makeStyles(theme => ({
  fieldset: {
    borderColor: '#ffad00',
    padding: 16,
    '& > legend': {
      padding: '0 6px',
    },
  },
  table: {
    marginTop: 16,
  },
}))

const columns = [
  {
    field: 'profileTags',
    title: 'Tagi',
    render: ({ profileTags }) =>
      profileTags.length > 0 ? profileTags.join(', ') : '*',
  },
  {
    field: 'merchantTypes',
    title: 'Maciņa tips',
    render: ({ merchantTypes, definedWallets }) => {
      const combinedWallets = [
        ...merchantTypes.map(mt => mt.description),
        ...definedWallets.map(dw => dw.name),
      ]
      return combinedWallets.join(', ')
    },
  },
  {
    field: 'value',
    title: 'Summa',
    render: ({ value }) => `${centsToEuros(value)?.toFixed(2)} €`,
  },
  {
    field: 'policyType',
    title: 'Saturs',
    render: ({ policyType }) =>
      policyType === 'SET' ? 'Pārrakstīt' : 'Papildināt',
  },
  {
    field: 'recurrenceInterval',
    title: 'Biežums',
    render: ({ period }) => {
      if (period === WEEKLY) {
        return '1x nedēļā'
      } else if (period === MONTHLY) {
        return '1x mēnesī'
      } else if (period === ANNUAL) {
        return '1x gadā'
      } else if (period === QUARTERLY) {
        return '1x ceturksnī'
      } else {
        return '-'
      }
    },
  },
  {
    field: 'nextApplicationAt',
    title: 'Nākamais izpildes datums',
  },
  {
    field: 'active',
    title: 'Aktīvs',
    render: ({ active }) => {
      return active ? 'Jā' : 'Nē'
    },
  },
]

export default function ScheduledWalletUpdates({ walletTypes }) {
  const classes = useStyles()
  const { tenantId } = useAuth()
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false)
  const [showAddEditPolicyUpdates, setShowAddEditPolicyUpdates] =
    React.useState(false)
  const [selectedUpdatePolicy, setSelectedUpdatePolicy] = React.useState(null)
  const [showConfirmRemoveUpdatePolicy, setShowConfirmRemoveUpdatePolicy] =
    React.useState(false)
  const [rows, setRows] = React.useState([])

  const { loading, refetch, data } = useQuery(LIST_WALLET_UPDATE_POLICIES, {
    variables: {
      tenantId: tenantId,
    },
  })

  const [removeUpdateWalletPolicy] = useMutation(
    REMOVE_UPDATE_WALLET_POLICY_MUTATION,
  )

  React.useEffect(() => {
    setRows(data?.walletUpdatePolicy?.walletUpdatePoliciesByTenant || [])
  }, [data])

  const onRemoveUpdatePolicy = () => {
    removeUpdateWalletPolicy({
      variables: {
        id: selectedUpdatePolicy.id,
      },
    })
      .then(() => {
        refetch()
        setShowSuccessMsg(true)
      })
      .catch(err => console.log(err))
      .finally(() => {
        setShowConfirmRemoveUpdatePolicy(false)
        setSelectedUpdatePolicy(null)
      })
  }

  return (
    <>
      {showAddEditPolicyUpdates && (
        <AddEditRuleModal
          onClose={() => {
            setShowAddEditPolicyUpdates(false)
            setSelectedUpdatePolicy(null)
          }}
          onSuccess={() => {
            setShowSuccessMsg(true)
            setShowAddEditPolicyUpdates(false)
            refetch()
          }}
          walletTypes={walletTypes}
        />
      )}
      {showConfirmRemoveUpdatePolicy && (
        <ConfirmModal
          onAccept={onRemoveUpdatePolicy}
          text="Vai tiešām vēlaties dzēst šo noteikumu?"
          onCancel={() => setShowConfirmRemoveUpdatePolicy(false)}
          onClose={() => setShowConfirmRemoveUpdatePolicy(false)}
        />
      )}
      {loading && <Loader />}
      <SnackbarSuccess
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      />
      <Box my={4}>
        <fieldset className={classes.fieldset}>
          <legend>Maciņu papildināšanas nosacījumi</legend>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowAddEditPolicyUpdates(true)}
            >
              PIEVIENOT JAUNU NOTEIKUMU
            </Button>
          </Box>
          <Box mt={4}>
            <CustomizedMaterialTable
              columns={columns}
              loading={loading}
              data={rows}
              actions={[
                {
                  icon: 'delete',
                  onClick: (_, row) => {
                    setSelectedUpdatePolicy(row)
                    setShowConfirmRemoveUpdatePolicy(true)
                  },
                  tooltip: 'Dzēst noteikumu',
                },
              ]}
            />
          </Box>
        </fieldset>
      </Box>
    </>
  )
}
