import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useQuery } from '@apollo/react-hooks'
import { LIST_TAGS_QUERY } from '../../gql/Queries'
import { useAuth } from '../providers/AuthProvider'

export function TagsAutocomplete({ variant = 'outlined', ...props }) {
  const { tenantId } = useAuth()
  const [data, setData] = React.useState([])
  const { loading } = useQuery(LIST_TAGS_QUERY, {
    variables: {
      tenantId,
    },
    onCompleted: queryData => setData(queryData?.tenantTags?.listTags || []),
  })

  return (
    <Autocomplete
      {...props}
      multiple
      options={data}
      filterSelectedOptions
      loading={loading}
      size="small"
      renderInput={params => (
        <TextField
          margin="dense"
          {...params}
          label="Tagi"
          variant={variant}
          fullWidth
        />
      )}
      noOptionsText="Nav atbilstošu ierakstu"
    />
  )
}
