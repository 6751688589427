export const getCombinedWallets = (merchantTypes = [], definedWallets = []) => {
  const _merchantTypes =
    merchantTypes.map(({ id, name, description }) => ({
      id,
      name,
      description,
      isDefinedWallet: false,
    })) || []
  const _definedWallets =
    definedWallets.map(({ id, name }) => ({
      id,
      name: id.toString(),
      description: name,
      isDefinedWallet: true,
    })) || []
  return [..._merchantTypes, ..._definedWallets]
}

export const getSelectedWallets = wallets => {
  return wallets.reduce(
    (result, current) => {
      if (current.checked) {
        if (current.isDefinedWallet) {
          result.definedWallets.push(current.id)
        } else {
          result.merchantTypes.push(current.id)
        }
      }
      return result
    },
    { definedWallets: [], merchantTypes: [] },
  )
}
