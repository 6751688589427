import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  signDocumentForm: {
    display: 'none',
  },
})

export default function SignDocumentForm({ method, action, fields }) {
  const classes = useStyles()
  const submitButton = useRef(null)

  useEffect(() => {
    if (submitButton?.current) {
      submitButton.current.click()
    }
  }, [])

  return (
    <form action={action} method={method} className={classes.signDocumentForm}>
      {fields.map(f => (
        <input
          key={f.name}
          type="text"
          name={f.name}
          defaultValue={f.value}
          readOnly
        />
      ))}
      <input type="submit" ref={submitButton} />
    </form>
  )
}
