import { gql } from 'apollo-boost'

export const GET_DOCUMENT_SIGN_FORM = gql`
  query GetDocumentSignForm($kind: DocumentSignKind!, $guid: String!) {
    getDocumentSignForm(input: { kind: $kind, guid: $guid }) {
      method
      action
      fields {
        name
        value
      }
    }
  }
`

export const LIST_DOCUMENT_SIGN_OPTIONS = gql`
  query ListDocumentSignOptions {
    listDocumentSignOptions {
      kind
      displayName
      logo
      active
    }
  }
`

export const GET_DOCUMENT_PARTY = gql`
  query GetDocumentParty($guid: String!) {
    getDocumentParty(guid: $guid) {
      displayName
      documentGuid
    }
  }
`

export const GET_DOCUMENT_SIGNATURE = gql`
  query GetDocumentSignature($guid: String!) {
    getDocumentSignature(guid: $guid) {
      firstName
      lastName
      personalCode
      documentParty {
        displayName
        documentGuid
      }
    }
  }
`

export const GET_TENANT_LEGAL_ENTITIES_QUERY = gql`
  query GetTenantLegalEntities($tenantId: Int!) {
    getTenantById(id: $tenantId) {
      legalEntities {
        id
        name
        registrationNumber
      }
    }
  }
`

export const LIST_DOCUMENT_TEMPLATES_QUERY = gql`
  query ListDocumentTemplates($ownerId: Int!) {
    listDocumentTemplates(ownerId: $ownerId) {
      displayName
      markup
      parties
      variables {
        key
        displayName
        description
      }
    }
  }
`

export const LIST_DOCUMENTS_QUERY = gql`
  query ListDocuments($ownerId: Int!, $tags: [String!]) {
    listDocuments(ownerId: $ownerId, tags: $tags) {
      guid
      displayName
      creationTime
      parties {
        displayName
        guid
        signatures {
          firstName
          lastName
          personalCode
        }
      }
    }
  }
`

export const LIST_PROFILES = gql`
  query ListProfiles(
    $tenant: Int!
    $limit: Int!
    $seek: Int!
    $enabled: Boolean!
    $multiContains: String
    $tags: [String!]!
    $employer: [Int!]
    $orderBy: String!
    $orderDesc: Boolean!
  ) {
    listProfiles(
      tenant: $tenant
      limit: $limit
      seek: $seek
      enabled: $enabled
      multiContains: $multiContains
      tags: $tags
      employer: $employer
      orderBy: $orderBy
      orderDesc: $orderDesc
    ) {
      list {
        profileId
        id
        tenant {
          id
          name
        }
        employer {
          id
          name
        }
        enabled
        phones
        entries {
          key
          value
        }
        wallets {
          definedWallet {
            id
            name
            additionalParams
          }
          type {
            id
            name
            description
          }
          balance
          reservedBalance
          availableBalance
        }
        tags
      }
      count
      totalCount
    }
  }
`

export const GET_WALLETS_AND_LEGAL_ENTITIES_QUERY = gql`
  query GetMerchantTypes($tenant: Int!, $tenantLong: Long!) {
    getTenantById(id: $tenant) {
      id
      name
      merchantTypes {
        id
        name
        description
      }
      legalEntities {
        id
        name
      }
    }
    definedWallets {
      byTenant(tenant: $tenantLong) {
        id
        name
      }
    }
  }
`

export const GET_PROFILE_QUERY = gql`
  query GetProfileById($id: String!, $tenant: Int) {
    getProfileById(id: $id, tenant: $tenant) {
      profileId
      id
      tenant {
        id
      }
      employer {
        id
        name
      }
      enabled
      phones
      entries {
        key
        value
      }
      wallets {
        type {
          id
          name
          description
        }
        definedWallet {
          id
          name
          additionalParams
        }
        availableBalance
      }
      tags
    }
  }
`

//AKA wallet types (old wallets) and defined wallets (new wallets)
export const LIST_MERCHANT_TYPES_QUERY = gql`
  query GetMerchantTypes($tenant: Int!, $tenantLong: Long!) {
    getTenantById(id: $tenant) {
      id
      merchantTypes {
        id
        name
        description
      }
    }
    definedWallets {
      byTenant(tenant: $tenantLong) {
        id
        name
      }
    }
  }
`

export const LIST_MERCHANTS_QUERY = gql`
  query getMerchants($tenantId: Int!) {
    getMerchants(limit: 5000, tenantId: $tenantId) {
      id
      name
      description
      address
      type {
        id
      }
    }
  }
`

export const LIST_TAGS_QUERY = gql`
  query listTags($tenantId: Int!) {
    tenantTags {
      listTags(tenantId: $tenantId)
    }
  }
`

export const LIST_TRANSACTIONS_QUERY = gql`
  query listTransactions(
    $tenant: Int
    $phoneNumberContains: String
    $timeFrom: Timestamp
    $timeTo: Timestamp
    $merchant: Int
    $merchantType: Int
    $skip: Int
    $limit: Int
    $orderBy: TransactionOrderBy
    $orderDesc: Boolean
  ) {
    listTransactions(
      tenant: $tenant
      phoneNumberContains: $phoneNumberContains
      timeFrom: $timeFrom
      timeTo: $timeTo
      merchant: $merchant
      merchantType: $merchantType
      skip: $skip
      limit: $limit
      orderBy: $orderBy
      orderDesc: $orderDesc
    ) {
      list {
        id
        time
        category
        profile {
          id
          phones
          entries {
            key
            value
          }
        }
        type {
          id
          name
          description
        }
        merchant {
          id
          name
          address
        }
        receipt {
          id
          fileId
          bytes
        }
        sum
      }
      totalCount
    }
  }
`

export const LIST_WALLET_UPDATE_POLICIES = gql`
  query getWalletUpdatePolicies($tenantId: Int!) {
    walletUpdatePolicy {
      walletUpdatePoliciesByTenant(tenantId: $tenantId) {
        id
        tenantId
        policyType
        profileTags
        merchantTypes {
          id
          name
          description
        }
        definedWallets {
          id
          name
          tenantId
          merchantTypes
        }
        value
        active
        nextApplicationAt
        period
        timeZone
        temporalAdjusterDetails {
          key
          arguments
        }
      }
    }
  }
`

export const LIST_USER_DEACTIVATION_SCHEDULE = gql`
  query userDeactivationScheduleQuery($profileId: Int!) {
    userDeactivationSchedule {
      byProfile(profileId: $profileId) {
        id
        profileId
        scheduledDate
        performedTime
        performed
      }
    }
  }
`
