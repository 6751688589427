import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles({
  snackbar: {
    backgroundColor: red[600],
    textAlign: 'center',
    display: 'block',
  },
})

export function SnackBarError({ open, onClose, message = 'Kļūda' }) {
  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={message}
        aria-describedby="client-snackbar"
      />
    </Snackbar>
  )
}
