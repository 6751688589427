import React from 'react'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  errorBox: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'red',
    margin: '10px',
    width: '50px',
    height: '50px',
  },
})

export default function SignDocumentErrorMessage({ message }) {
  const classes = useStyles()
  return (
    <Box className={classes.errorBox}>
      <ErrorIcon className={classes.icon} />
      <h2>{message}</h2>
    </Box>
  )
}
