import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_DOCUMENT_PARTY } from '../../../gql/Queries'
import Box from '@material-ui/core/Box'
import Loader from '../../elements/Loader'
import { makeStyles } from '@material-ui/core/styles'
import SignDocumentOptions from './SignDocumentOptions'
import SignDocumentErrorMessage from './SignDocumentErrorMessage'

const useStyles = makeStyles({
  preview: {
    position: 'fixed',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '85%',
  },
  actions: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '15%',
    display: 'flex',
    alignItems: 'center',
  },
  party: {
    width: '34%',
  },
  options: {
    width: '66%',
  },
  optionsHeader: {
    marginBlockEnd: 0,
    marginBottom: 0,
  },
})

export default function SignDocument() {
  const classes = useStyles()
  const { guid } = useParams()
  const { loading, error, data } = useQuery(GET_DOCUMENT_PARTY, {
    variables: { guid },
  })

  const partyExists = guid && !error && data?.getDocumentParty
  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" height="100%">
      {partyExists ? (
        <>
          <iframe
            title={'Dokumenta parakstīšāna'}
            className={classes.preview}
            src={`${process.env.REACT_APP_END_POINT}/rest/document.pdf?guid=${data.getDocumentParty.documentGuid}`}
          />
          <Box px={8} mt={1} className={classes.actions}>
            <Box className={classes.party}>
              <h3 className={classes.optionsHeader}>
                Autorizējoties, jūs parakstīsieties laukā:
              </h3>
              <h2>"{data.getDocumentParty.displayName}"</h2>
            </Box>
            <Box className={classes.options}>
              <h3 className={classes.optionsHeader}>
                Pieejamie autorizācijas veidi:
              </h3>
              <SignDocumentOptions />
            </Box>
          </Box>
        </>
      ) : (
        <SignDocumentErrorMessage message={'Dokuments neeksistē'} />
      )}
    </Box>
  )
}
