import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CancelIcon from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { useAuth } from '../providers/AuthProvider'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
  },
})

export default function ProfileModal({ onClose }) {
  const {
    id,
    firstName,
    familyName,
    username,
    updateUserInfo,
    changeUserPassword,
  } = useAuth()
  const classes = useStyles()
  const [formState, setFormState] = React.useState({
    id,
    username,
    firstName,
    familyName,
    password: '',
  })
  const [formErrors, setFormErrors] = React.useState({})

  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value })

  const isFormValid = () => {
    const { firstName, familyName, username } = formState
    const _errors = []
    if (!firstName) _errors['firstName'] = 'Šis lauks ir obligāts'

    if (!familyName) _errors['familyName'] = 'Šis lauks ir obligāts'

    if (!username) _errors['familyName'] = 'Šis lauks ir obligāts'

    // if(password&&password.length < 6)
    //     _errors['password']='';

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    updateUserInfo([
      {
        key: 'firstName',
        value: formState.firstName,
      },
      {
        key: 'familyName',
        value: formState.familyName,
      },
    ])
      .then(() => {
        if (formState.password)
          changeUserPassword(formState.password).then(() => onClose())
        else onClose()
      })
      .catch(err => console.error(err))
  }

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Mans profils
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="dense"
              label="Vārds"
              fullWidth
              name="firstName"
              autoComplete="off"
              value={formState.firstName}
              onChange={onFieldChange}
              helperText={formErrors.firstName}
              error={Boolean(formErrors.firstName)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Uzvārds"
              fullWidth
              name="familyName"
              autoComplete="off"
              value={formState.familyName}
              onChange={onFieldChange}
              helperText={formErrors.familyName}
              error={Boolean(formErrors.familyName)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Lietotāja vārds"
              fullWidth
              name="username"
              disabled
              autoComplete="off"
              value={formState.username}
              onChange={onFieldChange}
              helperText={formErrors.username}
              error={Boolean(formErrors.username)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Parole"
              fullWidth
              type="password"
              name="password"
              autoComplete="off"
              value={formState.password}
              onChange={onFieldChange}
              helperText={formErrors.password}
              error={Boolean(formErrors.password)}
            />
          </DialogContent>
          <DialogActions>
            <Box px={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                SAGLABĀT
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
