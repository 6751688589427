import React from 'react'
import Box from '@material-ui/core/Box'
import { formatDateTime } from './Date'
import { centsToEuros } from './Money'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import IconButton from '@material-ui/core/IconButton'

export const normalizeTransaction = transaction => {
  const firstNameEntry = transaction.profile.entries.find(
    e => e.key === 'firstName',
  )
  const familyNameEntry = transaction.profile.entries.find(
    e => e.key === 'familyName',
  )
  return {
    id: transaction.id,
    phones: transaction.profile.phones,
    firstName: firstNameEntry?.value || '',
    familyName: familyNameEntry?.value || '',
    dateTime: formatDateTime(transaction.time),
    merchantType: transaction.type?.description,
    merchant: transaction.merchant?.name,
    address: transaction.merchant?.address,
    total: centsToEuros(transaction.sum),
    receipt: transaction.receipt,
  }
}

export const TransactionOrderBy = {
  TX_ID: 'TX_ID',
  PROFILE_ID: 'PROFILE_ID',
  TIME: 'TIME',
  MERCHANT: 'MERCHANT',
  MERCHANT_TYPE: 'MERCHANT_TYPE',
  SUM: 'SUM',
}

export const transactionColumns = [
  { field: 'id', title: 'Darījuma ID', sortId: TransactionOrderBy.TX_ID },
  {
    field: 'phones',
    title: 'Telefons',
    render: ({ phones }) => (
      <div style={{ whiteSpace: 'pre-wrap' }}>{phones.join('\n')}</div>
    ),
  },
  { field: 'firstName', title: 'Vārds' },
  { field: 'familyName', title: 'Uzvārds' },
  {
    field: 'dateTime',
    title: 'Datums, laiks',
    sortId: TransactionOrderBy.TIME,
  },
  {
    field: 'merchantType',
    title: 'Pakalpojuma veids',
    sortId: TransactionOrderBy.MERCHANT_TYPE,
    render: ({ merchantType, receipt }) => (
      <Box display="flex" alignItems="center">
        {merchantType}
        {receipt && (
          <IconButton
            color="primary"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_END_POINT}/rest/download?file_id=${receipt.fileId}`,
                '_blank',
              )
            }
          >
            <ReceiptOutlinedIcon />
          </IconButton>
        )}
      </Box>
    ),
  },
  {
    field: 'merchant',
    title: 'Pakalpojuma sniedzējs',
    sortId: TransactionOrderBy.MERCHANT,
  },
  {
    field: 'address',
    title: 'Pakalpojuma sniedzēja adrese',
    cellStyle: {
      minWidth: 320,
    },
  },
  {
    field: 'total',
    title: 'Kopā',
    render: ({ total }) => `${total.toFixed(2)} €`,
    sortId: TransactionOrderBy.SUM,
  },
]
