import React from 'react'
import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  loader: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1301, // mui modal z-index = 1300
  },
})

export default function Loader() {
  const classes = useStyles()

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  )
}
