import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider as Provider } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { rollbar } from '../../rollbar'

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_END_POINT}/graphql`,
  request: operation => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    })
  },
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(error => {
        rollbar.error(JSON.stringify(error))
      })
    const tokenError = graphQLErrors?.[0]?.extensions?.Code === 1400
    if (tokenError) {
      localStorage.clear()
      window.location.href = '/'
    }
  },
  typeDefs: gql`
    enum DocumentSignKind {
      citadele
      dnb
      eparaksts
      seb
      swedbank
    }
  `,
})

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

export default function ApolloProvider({ children }) {
  return <Provider client={client}>{children}</Provider>
}
