import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import SignIn from './components/views/SignIn'
import SignDocument from './components/views/sign/SignDocument'
import ThemeProvider from './components/providers/ThemeProvider'
import Main from './components/views/Main'
import ApolloProvider from './components/providers/ApolloProvider'
import AuthProvider from './components/providers/AuthProvider'
import SignDocumentSuccess from './components/views/sign/SignDocumentSuccess'
import SignDocumentErrorMessage from './components/views/sign/SignDocumentErrorMessage'
import SignDocumentInit from './components/views/sign/SignDocumentInit'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { rollbarConfig } from './rollbar'

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
export default function App() {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <ApolloProvider>
          <AuthProvider>
            <ThemeProvider>
              <Router>
                <Switch>
                  <Route path="/login">
                    <SignIn />
                  </Route>
                  <Route path="/signdocument/:guid">
                    <SignDocument />
                  </Route>
                  <Route path="/signdocumentinit">
                    <SignDocumentInit />
                  </Route>
                  <Route path="/signdocumentfailure">
                    <SignDocumentErrorMessage
                      message={'Nav izdevies parakstīt dokumentu'}
                    />
                  </Route>
                  <Route path="/signdocumentsuccess/:guid">
                    <SignDocumentSuccess />
                  </Route>
                  <PrivateRoute path="/">
                    <Main />
                  </PrivateRoute>
                </Switch>
              </Router>
            </ThemeProvider>
          </AuthProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </RollbarProvider>
  )
}
