import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import { TagsAutocomplete } from '../../../elements/TagsAutocomplete'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import { isValidNumberInput } from '../../../../utils/validation'
import MuiTextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 360,
  },
  button: {
    minWidth: 200,
  },
  tagsHelperText: {
    fontSize: 10,
    color: red[600],
  },
  checkbox: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogActions: {
    '&.MuiDialogActions-root': {
      justifyContent: 'center',
      marginTop: 8,
    },
  },
  bottomCheckboxLabel: {
    fontSize: '0.875rem',
    marginTop: 9,
  },
})

const TopupWalletsByTagsModal = ({ onSuccess, onClose, wallets }) => {
  const classes = useStyles()

  //form data
  const [formState, setFormState] = React.useState({
    wallets: wallets.map(w => ({ ...w, amount: '', checked: false })),
    tags: [],
    modifyAllUserWallets: false,
  })
  const [formErrors, setFormErrors] = React.useState({})
  const inputRefs = React.useRef(new Array(wallets.length))

  const onFieldChange = ({ target }, index) => {
    const editedValue = target.value.replace(',', '.').trim()
    if (!isValidNumberInput(editedValue)) {
      return
    }
    const updatedWallets = [...formState.wallets]
    updatedWallets[index].amount = editedValue
    setFormState({ ...formState, wallets: updatedWallets })
  }

  const onCheckboxChange = (checked, index) => {
    const updatedWallets = [...formState.wallets]
    updatedWallets[index].checked = checked
    setFormState({ ...formState, wallets: updatedWallets })
    setTimeout(() => inputRefs.current[index].focus(), 100)
  }
  const isFormValid = () => {
    const _errors = []

    //...

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    //...
    onSuccess({
      ...formState,
      wallets: formState.wallets.filter(w => w.checked),
    })
  }
  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            Papildināt visus maciņus
          </DialogTitle>
          <DialogContent>
            {formState.wallets.map(({ name, description, checked }, index) => (
              <Box display="flex" key={name}>
                <Checkbox
                  checked={checked}
                  color="primary"
                  className={classes.checkbox}
                  disableRipple
                  onChange={(_, _checked) => onCheckboxChange(_checked, index)}
                />
                <MuiTextField
                  inputRef={el => (inputRefs.current[index] = el)}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  label={description}
                  disabled={!checked}
                  name={name}
                  value={formState.wallets[index].amount}
                  onChange={event => onFieldChange(event, index)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  helperText={formErrors.dinnerWallet}
                  error={Boolean(formErrors.dinnerWallet)}
                />
              </Box>
            ))}

            <Box ml={4}>
              <TagsAutocomplete
                onChange={(event, value) =>
                  setFormState({ ...formState, tags: value })
                }
                value={formState.tags}
              />
              <div className={classes.tagsHelperText}>
                *ja netiks izvēlēta neviena atzīme, maciņi izvēlētājā kategorijā
                tiks papidināti visiem lietotājiem
              </div>
            </Box>
            <Box mt={2} display="flex" alignItems="flex-start">
              <Checkbox
                checked={formState.modifyAllUserWallets}
                color="primary"
                className={classes.checkbox}
                onChange={(_, _checked) =>
                  setFormState({ ...formState, modifyAllUserWallets: _checked })
                }
              />
              <div>
                <div className={classes.bottomCheckboxLabel}>
                  Izveidot neaktīvos maciņus un tos papildināt
                </div>
                <div className={classes.tagsHelperText}>
                  *ja tiks izvēlēta šī iespēja, tad tiks izveidoti maciņi arī
                  tiem lietotājiem, kuriem šobrīd tie nav aktīvi un maciņš
                  papildināts ar norādīto naudas summu
                </div>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                Papildināt
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}

export default TopupWalletsByTagsModal
