import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import { TagsAutocomplete } from '../../../elements/TagsAutocomplete'
import { useMutation } from '@apollo/react-hooks'
import { useAuth } from '../../../providers/AuthProvider'
import { CREATE_UPDATE_WALLET_POLICY_MUTATION } from '../../../../gql/Mutations'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import red from '@material-ui/core/colors/red'
import InputAdornment from '@material-ui/core/InputAdornment'
import { eurosToCents } from '../../../../utils/Money'
import { Radio, RadioGroup } from '@material-ui/core'
import DatePicker from '../../../elements/DatePicker'
import { getSelectedWallets } from '../../../../utils/walletUtils'
import { isValidNumberInput } from '../../../../utils/validation'
import MuiTextField from '@material-ui/core/TextField'

export const WEEKLY = 'WEEKLY'
export const MONTHLY = 'MONTHLY'
export const ANNUAL = 'ANNUAL'
export const QUARTERLY = 'QUARTERLY'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 200,
  },
  tagsHelperText: {
    fontSize: 10,
    color: red[600],
  },
  checkbox: {
    paddingTop: 2,
    paddingBottom: 2,
  },
})

const PERIODS = [
  {
    displayName: '1x nedēļā',
    value: WEEKLY,
  },
  {
    displayName: '1x mēnesī',
    value: MONTHLY,
  },
  {
    displayName: '1x ceturksnī',
    value: QUARTERLY,
  },
  {
    displayName: '1x gadā',
    value: ANNUAL,
  },
]

const DEFAULT_PERIOD = PERIODS[1] // monthly

const POLICY_TYPES = [
  {
    displayName: 'Papildināt',
    value: 'ADD',
    description: 'papildināt esošo maciņa atlikumu',
    descriptionFull:
      'Papildināt esošo maciņa atlikumu, pieskaitot norādīto summu esošajam maciņa atlikumam',
  },
  {
    displayName: 'Pārrakstīt',
    value: 'SET',
    description: 'aizvietot maciņa saturu',
    descriptionFull:
      'Aizvietot maciņa saturu, aizstājot esošo maciņa saturu ar norādīto summu',
  },
]

const DEFAULT_POLICY_TYPE = POLICY_TYPES[0] // add

const isLastDayOfMonth = date => {
  if (!date) {
    return false
  }
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDayOfMonth.getDate() === date.getDate()
}

export default function AddEditRuleModal({ onClose, onSuccess, walletTypes }) {
  const { tenantId } = useAuth()
  const classes = useStyles()
  const [createWalletUpdatePolicy] = useMutation(
    CREATE_UPDATE_WALLET_POLICY_MUTATION,
  )
  const [showLastDayOfMonthCheckbox, setShowLastDayOfMonthCheckbox] =
    useState(false)

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  //init form data
  const [formState, setFormState] = React.useState({
    period: DEFAULT_PERIOD,
    policyType: DEFAULT_POLICY_TYPE,
    tags: [],
    active: true,
    wallets: walletTypes.map(w => {
      return {
        ...w,
        checked: false,
      }
    }),
    amount: '',
    date: tomorrow,
    lastDayOfMonthOption: false,
  })

  useEffect(() => {
    if (
      isLastDayOfMonth(formState.date) &&
      formState.period.value === MONTHLY
    ) {
      setShowLastDayOfMonthCheckbox(true)
    } else {
      setFormState(_formState => ({
        ..._formState,
        lastDayOfMonthOption: false,
      }))
      setShowLastDayOfMonthCheckbox(false)
    }
  }, [formState.date, formState.period.value])

  const [formErrors, setFormErrors] = React.useState({})

  const onFieldChange = ({ target }) => {
    const editedValue = target.value.replace(',', '.').trim()
    if (!isValidNumberInput(editedValue)) {
      return
    }
    setFormState(prevState => ({ ...prevState, [target.name]: editedValue }))
  }

  const onCheckboxChange = (checked, index) => {
    const updatedWallets = [...formState.wallets]
    updatedWallets[index].checked = checked
    setFormState({ ...formState, wallets: updatedWallets })
  }

  const isFormValid = () => {
    const { amount, period, policyType, wallets } = formState
    const _errors = []

    const isNoWalletsChecked = wallets.filter(w => w.checked).length === 0

    if (isNoWalletsChecked) {
      _errors['wallets'] = 'Nav atzīmēts neviens maciņš'
    }
    if (!amount || amount < 0)
      _errors['amount'] = 'Atlikums nedrīkst būt negatīvs'
    if (!period) _errors['period'] = 'Lauks ir obligāti jāaizpilda'
    if (!policyType) _errors['policyType'] = 'Lauks ir obligāti jāaizpilda'

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    const selectedWallets = getSelectedWallets(formState.wallets)

    const input = {
      tenantId,
      policyType: formState.policyType.value,
      merchantTypes: selectedWallets.merchantTypes,
      definedWallets: selectedWallets.definedWallets,
      profileTags: formState.tags,
      value: eurosToCents(formState.amount),
      active: formState.active,
      firstApplicationAt:
        formState.date && formState.date.toISOString().split('T')[0],
      period: formState.period.value,
      temporalAdjusterDetails: formState.lastDayOfMonthOption
        ? {
            key: 'LAST_DAY_OF_MONTH',
            arguments: [],
          }
        : null,
    }

    createWalletUpdatePolicy({
      variables: {
        input,
      },
    })
      .then(() => {
        onSuccess()
      })
      .catch(err => console.error(err))
  }

  return (
    <>
      <Dialog fullWidth open onClose={onClose}>
        <Box pb={2}>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon
              className={classes.closeIcon}
              color="primary"
              onClick={onClose}
            />
          </Box>
          <Box px={2}>
            <DialogTitle className={classes.dialogTitle}>
              <Box display="flex" justifyContent="center">
                JAUNS NOTEIKUMS
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box>Maciņa tips</Box>
              <Box mt={2}>
                {formState.wallets.map(
                  ({ name, description, checked }, index) => (
                    <FormControlLabel
                      key={name}
                      control={
                        <Checkbox
                          checked={checked}
                          color="primary"
                          className={classes.checkbox}
                          disableRipple
                          onChange={(_, _checked) =>
                            onCheckboxChange(_checked, index)
                          }
                        />
                      }
                      label={description}
                    />
                  ),
                )}
                {!!formErrors.wallets && (
                  <Box ml={2} className={classes.tagsHelperText}>
                    {formErrors.wallets}
                  </Box>
                )}
              </Box>
              <Box mt={1}>
                <TagsAutocomplete
                  onChange={(event, value) =>
                    setFormState(_formState => ({
                      ..._formState,
                      tags: value,
                    }))
                  }
                  value={formState.tags}
                />
                <div className={classes.tagsHelperText}>
                  *ja netiks izvēlēta neviena atzīme, tad nosacījums tiks
                  piemērots visiem lietotājiem
                </div>
              </Box>
              <Box mt={1}>
                <MuiTextField
                  size="small"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  name="amount"
                  label="Summa"
                  value={formState.amount}
                  onChange={onFieldChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={formErrors.amount}
                  error={Boolean(formErrors.amount)}
                />
              </Box>
              <Box style={{ marginTop: '0.5rem' }}>
                <RadioGroup
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                  value={formState.policyType.value}
                  onChange={(_, value) => {
                    setFormState(_formState => ({
                      ..._formState,
                      policyType:
                        POLICY_TYPES.find(pt => pt.value === value) ||
                        DEFAULT_POLICY_TYPE,
                    }))
                  }}
                >
                  {POLICY_TYPES.map(type => {
                    return (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={<Radio color="primary" />}
                        title={type.descriptionFull}
                        label={
                          <Box>
                            <span>{type.displayName} </span>
                            <span style={{ fontSize: '0.625rem' }}>
                              ({type.description})
                            </span>
                          </Box>
                        }
                      />
                    )
                  })}
                </RadioGroup>
              </Box>
              <Box style={{ marginTop: '0.5rem' }}>
                <Box>Izpildīt noteikumu</Box>
                <RadioGroup
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                  value={formState.period.value}
                  onChange={(_, value) =>
                    setFormState(_formState => ({
                      ..._formState,
                      period:
                        PERIODS.find(p => p.value === value) || DEFAULT_PERIOD,
                    }))
                  }
                >
                  {PERIODS.map(period => {
                    return (
                      <FormControlLabel
                        key={period.value}
                        value={period.value}
                        control={<Radio color="primary" />}
                        label={period.displayName}
                      />
                    )
                  })}
                </RadioGroup>
              </Box>
              <Box>Diena, no kuras sākt noteikuma izpildi</Box>
              <Box display="flex" alignItems="center">
                <DatePicker
                  value={formState.date}
                  inputVariant="standard"
                  minDate={tomorrow}
                  label=""
                  onChange={date => {
                    setFormState({ ...formState, date })
                  }}
                />
                {showLastDayOfMonthCheckbox && (
                  <FormControlLabel
                    style={{ marginLeft: 4 }}
                    control={
                      <Checkbox
                        checked={formState.lastDayOfMonthOption}
                        color="primary"
                        className={classes.checkbox}
                        disableRipple
                        onChange={(_, _checked) =>
                          setFormState({
                            ...formState,
                            lastDayOfMonthOption: _checked,
                          })
                        }
                      />
                    }
                    label="Vienmēr izpildīt mēneša pēdējā dienā"
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Box mt={1} mr={2}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={onSubmit}
                  color="primary"
                >
                  SAGLABĀT
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
