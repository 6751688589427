import React from 'react'
import { GET_DOCUMENT_SIGN_FORM } from '../../../gql/Queries'
import { useLocation } from 'react-router-dom'
import Loader from '../../elements/Loader'
import SignDocumentForm from './SignDocumentForm'
import SignDocumentErrorMessage from './SignDocumentErrorMessage'
import { useQuery } from '@apollo/react-hooks'

export default function SignDocumentInit() {
  const query = new URLSearchParams(useLocation().search)
  const guid = query.get('guid')
  const kind = query.get('kind')
  const { data, loading } = useQuery(GET_DOCUMENT_SIGN_FORM, {
    variables: { guid, kind },
  })
  return loading ? (
    <Loader />
  ) : data?.getDocumentSignForm ? (
    <SignDocumentForm {...data.getDocumentSignForm} />
  ) : (
    <SignDocumentErrorMessage message={'Nav iespējams parakstīt dokumentu'} />
  )
}
