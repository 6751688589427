import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Tooltip from '@material-ui/core/Tooltip'
import { TagsAutocomplete } from '../../elements/TagsAutocomplete'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NEW_PROFILE_MUTATION, PROFILE_MUTATION } from '../../../gql/Mutations'
import { GET_PROFILE_QUERY } from '../../../gql/Queries'
import { centsToEuros, eurosToCents } from '../../../utils/Money'
import { TopupWalletByProfileAndWalletTypeModal } from './topup-wallets/TopupWalletByProfileAndWalletTypeModal'
import Loader from '../../elements/Loader'
import { useAuth } from '../../providers/AuthProvider'
import LegalEntitiesAutocomplete from '../documents/LegalEntitiesAutocomplete'
import ScheduleUserDeactivationSection from '../../elements/ScheduleUserDeactivationSection'
import { FormControlLabel } from '@material-ui/core'
import MuiTextField from '@material-ui/core/TextField'
import { isValidNumberInput } from '../../../utils/validation'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 200,
  },
})

const getLegitWallets = wallets => {
  const legitWallets = []
  wallets.forEach(_w => {
    if (_w.availableBalance !== undefined && _w.availableBalance !== '') {
      legitWallets.push({
        definedWallet: _w.isDefinedWallet ? _w.id : undefined,
        type: _w.isDefinedWallet ? undefined : _w.id,
        balance: eurosToCents(Number(_w.availableBalance)),
      })
    }
  })
  return legitWallets
}

export default function AddEditProfileModal({
  profileId,
  onClose,
  onSuccess,
  walletTypes,
  profileIdNumber,
}) {
  const { tenantId } = useAuth()
  const isEditMode = Boolean(profileId)

  const { loading: loadingProfileByIdData } = useQuery(GET_PROFILE_QUERY, {
    variables: {
      id: profileId,
      tenant: tenantId,
    },
    skip: !isEditMode,
    onCompleted: data => {
      if (data?.getProfileById) {
        const profile = data.getProfileById
        const firstNameEntry = profile.entries.find(e => e.key === 'firstName')
        const familyNameEntry = profile.entries.find(
          e => e.key === 'familyName',
        )
        setFormState({
          ...profile,
          phones: profile.phones.length > 0 ? profile.phones : [''],
          firstName: firstNameEntry?.value || '',
          familyName: familyNameEntry?.value || '',
          tenant: profile.tenant.id,
          employer: profile.employer,
          wallets: walletTypes.map(_w => {
            const profileWallet = _w.isDefinedWallet
              ? profile.wallets.find(_pw => _pw.definedWallet?.id === _w.id)
              : profile.wallets.find(_pw => _pw.type?.id === _w.id)
            const availableBalance =
              profileWallet?.availableBalance &&
              centsToEuros(profileWallet?.availableBalance)
            return {
              isDefinedWallet: _w.isDefinedWallet,
              id: _w.id,
              name: _w.name,
              description: _w.description,
              availableBalance: availableBalance,
            }
          }),
          tags: profile.tags,
        })
      }
    },
  })

  const [addProfile] = useMutation(NEW_PROFILE_MUTATION)

  const [updateProfile] = useMutation(PROFILE_MUTATION)
  const classes = useStyles()
  //form data
  const [formState, setFormState] = React.useState({
    phones: [''],
    firstName: '',
    familyName: '',
    wallets: walletTypes.map(_w => ({
      isDefinedWallet: _w.isDefinedWallet,
      id: _w.id,
      name: _w.name,
      description: _w.description,
      availableBalance: '',
    })),
    tags: [],
    enabled: true,
    employer: null,
  })
  const [formErrors, setFormErrors] = React.useState({})
  const [showTopupWalletModal, setShowTopupWalletModal] = React.useState(false)
  const [selectedWalletType, setSelectedWalletType] = React.useState(null)

  const onFieldChange = ({ target }) =>
    setFormState(_formState => ({
      ..._formState,
      [target.name]: target.value,
    }))

  const isFormValid = () => {
    const { phones, firstName, familyName, wallets } = formState
    const _errors = []

    if (!firstName.trim().match(/^[\s\p{L}]+$/u))
      _errors['firstName'] = 'Lauks ir obligāti jāaizpilda.'

    if (!familyName.trim().match(/^[-\s\p{L}]+$/u))
      _errors['familyName'] = 'Lauks ir obligāti jāaizpilda.'

    if (!formState.employer)
      _errors['employer'] = 'Lauks ir obligāti jāaizpilda.'

    phones.forEach((_phone, index) => {
      if (!_phone.trim().match(/^(\+?[0-9][0-9]{10,14}|[0-9]{8})$/))
        _errors[`phone_${index}`] = 'Laukā jānorāda derīgs tālruņa numurs.'
    })

    wallets.forEach(_wallet =>
      _wallet.availableBalance < 0
        ? (_errors[_wallet.name] = 'Atlikumam jābūt pozitīvam')
        : false,
    )
    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    const legitWallets = getLegitWallets(formState.wallets)

    //gql mutation
    if (isEditMode) {
      //prepare data

      const data = {
        id: profileId,
        tenant: formState.tenant,
        input: {
          enabled: formState.enabled,
          employer: formState.employer?.id,
          wallets: legitWallets,
          phones: formState.phones.filter(_phone => _phone), //remove empty elements
          entries: [
            {
              key: 'firstName',
              value: formState.firstName.trim(),
            },
            {
              key: 'familyName',
              value: formState.familyName.trim(),
            },
          ],
          tags: formState.tags,
        },
      }
      console.log('updateProfile', data)
      updateProfile({ variables: data })
        .then(id => onSuccess())
        .catch(onError)
    } else {
      //prepare data
      const data = {
        tenant: tenantId,
        input: {
          enabled: true,
          employer: formState.employer?.id,
          wallets: legitWallets,
          phones: formState.phones.filter(_phone => _phone), //remove empty elements
          entries: [
            {
              key: 'firstName',
              value: formState.firstName,
            },
            {
              key: 'familyName',
              value: formState.familyName,
            },
          ],
          tags: formState.tags,
        },
      }
      console.log('addProfile', data)
      addProfile({ variables: data })
        .then(id => onSuccess())
        .catch(onError)
    }
  }

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors?.[0]?.extensions?.field === 'phones') {
      const duplicatedPhones = graphQLErrors[0].extensions?.value
      console.log({ duplicatedPhones })
      setFormErrors(_formErrors => ({
        ..._formErrors,
        ...duplicatedPhones.reduce((acc, curr) => {
          const duplicatedPhoneIndex = formState.phones.indexOf(curr)
          return {
            ...acc,
            [`phone_${duplicatedPhoneIndex}`]:
              'Lietotājs ar šādu telefona numuru jau eksistē',
          }
        }, {}),
      }))
    }
  }

  if (loadingProfileByIdData) return <Loader />

  return (
    <>
      {showTopupWalletModal && selectedWalletType && (
        <TopupWalletByProfileAndWalletTypeModal
          onClose={() => setShowTopupWalletModal(false)}
          onSuccess={amount => {
            setShowTopupWalletModal(false)
            setFormState(_formState => ({
              ..._formState,
              wallets: formState.wallets.map(_w =>
                _w.id === selectedWalletType.id
                  ? {
                      ..._w,
                      availableBalance:
                        Number(_w.availableBalance) + Number(amount),
                    }
                  : _w,
              ),
            }))
            setSelectedWalletType(null)
          }}
          profile={formState}
          walletType={selectedWalletType.description}
        />
      )}
      <Dialog fullWidth open onClose={onClose}>
        <Box pb={2}>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon
              className={classes.closeIcon}
              color="primary"
              onClick={onClose}
            />
          </Box>
          <Box px={2}>
            <DialogTitle className={classes.dialogTitle}>
              <Box display="flex" justifyContent="center">
                {isEditMode ? 'REDIĢĒT LIETOTĀJU' : 'JAUNS LIETOTĀJS'}
              </Box>
            </DialogTitle>
            <DialogContent>
              <TextField
                variant="outlined"
                margin="dense"
                label="Vārds"
                fullWidth
                name="firstName"
                autoComplete="off"
                value={formState.firstName}
                onChange={onFieldChange}
                helperText={formErrors.firstName}
                error={Boolean(formErrors.firstName)}
              />

              <TextField
                variant="outlined"
                margin="dense"
                label="Uzvārds"
                fullWidth
                name="familyName"
                autoComplete="off"
                value={formState.familyName}
                onChange={onFieldChange}
                helperText={formErrors.familyName}
                error={Boolean(formErrors.familyName)}
              />

              <LegalEntitiesAutocomplete
                onChange={(event, value) =>
                  setFormState(_formState => ({
                    ..._formState,
                    employer: value,
                  }))
                }
                value={formState.employer}
                variant="outlined"
                helperText={formErrors.employer}
                error={Boolean(formErrors.employer)}
              />

              <TagsAutocomplete
                onChange={(event, value) =>
                  setFormState(_formState => ({ ..._formState, tags: value }))
                }
                value={formState.tags}
              />

              {formState.phones.map((phone, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    variant="outlined"
                    margin="dense"
                    label={`Telefona numurs ${index > 0 ? index + 1 : ''}`}
                    fullWidth
                    autoComplete="off"
                    value={formState.phones[index]}
                    onChange={({ target: { value } }) =>
                      setFormState(_formState => ({
                        ..._formState,
                        phones: _formState.phones.map((_phone, _index) =>
                          _index === index ? value : _phone,
                        ),
                      }))
                    }
                    helperText={formErrors[`phone_${index}`]}
                    error={Boolean(formErrors[`phone_${index}`])}
                  />
                  <Box width={40}>
                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setFormState(_formState => ({
                            ..._formState,
                            phones: [..._formState.phones, ''],
                          }))
                        }
                      >
                        <Tooltip title="Pievienot vēl vienu telefona numuru">
                          <ControlPointIcon />
                        </Tooltip>
                      </IconButton>
                    ) : (
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          setFormState(_formState => ({
                            ..._formState,
                            phones: _formState.phones.filter(
                              (_, _index) => _index !== index,
                            ),
                          }))
                        }
                      >
                        <Tooltip title="Neizmantot šo telefona numuru">
                          <HighlightOffIcon />
                        </Tooltip>
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}

              {formState.wallets.map(wallet => {
                return (
                  <Box display="flex" alignItems="center" key={wallet.id}>
                    <MuiTextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      placeholder="Izveidot maciņu"
                      label={wallet.description}
                      value={wallet.availableBalance ?? ''}
                      onChange={({ target: { value } }) => {
                        const editedValue = value.replace(',', '.').trim()
                        if (!isValidNumberInput(editedValue)) {
                          return
                        }
                        setFormState(_formState => ({
                          ..._formState,
                          wallets: _formState.wallets.map(_w =>
                            _w.id === wallet.id
                              ? {
                                  ..._w,
                                  availableBalance: editedValue,
                                }
                              : _w,
                          ),
                        }))
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={formErrors[wallet.name]}
                      error={Boolean(formErrors[wallet.name])}
                    />
                    <Box width={40}>
                      {wallet.availableBalance !== undefined &&
                        wallet.availableBalance !== '' && (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setSelectedWalletType(wallet)
                              setShowTopupWalletModal(true)
                            }}
                          >
                            <Tooltip title="Papildināt">
                              <ControlPointIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                    </Box>
                  </Box>
                )
              })}
              {isEditMode && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.enabled}
                      color="primary"
                      onChange={(_, checked) =>
                        setFormState(_formState => ({
                          ..._formState,
                          enabled: checked,
                        }))
                      }
                    />
                  }
                  label={
                    formState.enabled
                      ? 'Aktīvs lietotājs'
                      : 'Neaktīvs lietotājs'
                  }
                  title="Aktivizēt/Deaktivizēt lietotāju uzreiz"
                />
              )}
              {isEditMode && (
                <ScheduleUserDeactivationSection
                  profileIdNumber={profileIdNumber}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Box mt={1} mr={2}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={onSubmit}
                  color="primary"
                >
                  {isEditMode ? 'Saglabāt' : 'Pievienot'}
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
