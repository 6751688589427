export function formatDateTime(dateTime) {
  if (!isValidDate(new Date(dateTime))) return ''
  return new Date(dateTime).toLocaleDateString('lv', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function isValidDate(dateTime) {
  return dateTime instanceof Date && !isNaN(dateTime)
}

export const getFirstLastDayOfMonth = () => {
  const today = new Date()
  const firstDayCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  )
  const lastDayCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
  )
  return { firstDayCurrentMonth, lastDayCurrentMonth }
}
