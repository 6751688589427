import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'

export const DownloadButton = ({ loading, onClickHandler }) => {
  return (
    <Box width={36} display="flex" alignItems="center" justifyContent="center">
      {loading ? (
        <CircularProgress title="Sagatavo datus eksportam" size={18} />
      ) : (
        <IconButton
          aria-label="export"
          title="Eksportēt xlsx"
          onClick={onClickHandler}
        >
          <SystemUpdateAltIcon />
        </IconButton>
      )}
    </Box>
  )
}
