import React from 'react'
import Box from '@material-ui/core/Box'
import { centsToEuros } from './Money'
import { getCombinedWallets } from './walletUtils'
import IconButton from '@material-ui/core/IconButton'
import ControlPointIcon from '@material-ui/icons/ControlPoint'

export const normalizeProfiles = profile => {
  const firstNameEntry = profile.entries.find(e => e.key === 'firstName')
  const familyNameEntry = profile.entries.find(e => e.key === 'familyName')

  return {
    id: profile.id,
    profileIdNumber: profile.profileId,
    phones: profile.phones,
    firstName: firstNameEntry?.value || '',
    familyName: familyNameEntry?.value || '',
    enabled: profile.enabled,
    employer: profile.employer,
    wallets: profile.wallets.map(w => ({
      isDefinedWallet: !!w.definedWallet,
      id: w.type?.id || w.definedWallet?.id,
      name: w.type?.name || w.definedWallet?.id.toString(),
      description: w.type?.description || w.definedWallet?.name,
      availableBalance: centsToEuros(w.availableBalance),
    })),
    tags: profile.tags,
  }
}

export const processWalletData = (data, topupWalletByProfile) => {
  const combinedWallets = getCombinedWallets(
    data?.getTenantById?.merchantTypes,
    data?.definedWallets?.byTenant,
  )

  const columns = [
    {
      field: 'phones',
      title: 'Telefons',
      sorting: false,
      render: ({ phones }) => (
        <div style={{ whiteSpace: 'pre-wrap' }}>{phones.join('\n')}</div>
      ),
    },
    {
      field: 'firstName',
      title: 'Vārds',
      customSort: () => 0,
    },
    {
      field: 'familyName',
      title: 'Uzvārds',
      customSort: () => 0,
    },
    {
      field: 'tags',
      title: 'Tagi',
      sorting: false,
      render: ({ tags }) => tags.join(', '),
    },
    ...combinedWallets.map(({ id, name, description }) => ({
      field: name,
      orderByKey: description,
      title: description,
      customSort: () => 0,
      render: profile => {
        const walletById = profile.wallets.find(w => w.id === id)
        if (walletById) {
          return (
            <Box display="flex" alignItems="center">
              {`${(walletById?.availableBalance || 0).toFixed(2)} €`}
              <IconButton
                color="primary"
                onClick={() => topupWalletByProfile(profile, walletById)}
              >
                <ControlPointIcon />
              </IconButton>
            </Box>
          )
        } else {
          return '-'
        }
      },
    })),
  ]

  return [combinedWallets, columns]
}
