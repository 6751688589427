import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'

const logoPath = `${process.env.PUBLIC_URL}/assets/images/labumi-logo.png`

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()} Mobilly SIA. <br /> All Rights Reserved
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWrapper: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  signIn: {
    margin: theme.spacing(3, 0, 2),
    padding: '10px 0',
  },
}))

export default function SignIn() {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const { login } = useAuth()

  const onLoginClick = () => {
    login(username, password)
      .then(() => {
        history.replace('/')
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const onEnterClick = ev => {
    if (ev.key === 'Enter') onLoginClick()
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item>
        <div className={classes.paper}>
          <img alt="logo" src={logoPath} height={100} />
          <form className={classes.formWrapper} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Lietotājs"
              autoFocus
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              onKeyPress={onEnterClick}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Parole"
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              onKeyPress={onEnterClick}
            />
            <Typography color={'error'} variant={'body2'}>
              {error}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signIn}
              onClick={onLoginClick}
            >
              IENĀKT
            </Button>
          </form>
        </div>
      </Grid>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Grid>
  )
}
