import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useQuery } from '@apollo/react-hooks'
import { LIST_MERCHANTS_QUERY } from '../../gql/Queries'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import { useAuth } from '../providers/AuthProvider'
import { sortAlphabetically } from '../../utils/common'

const useStyles = makeStyles({
  address: {
    fontSize: 10,
    color: grey[700],
  },
})

export function MerchantsAutocomplete(props) {
  const { tenantId } = useAuth()
  const classes = useStyles()
  const [data, setData] = React.useState([])
  const { loading } = useQuery(LIST_MERCHANTS_QUERY, {
    variables: {
      tenantId,
    },
    onCompleted: data => {
      if (data?.getMerchants) {
        const merchants = [...data.getMerchants]
        setData(
          merchants.sort((prev, next) =>
            sortAlphabetically(prev, next, 'name'),
          ),
        )
      }
    },
  })

  return (
    <Autocomplete
      {...props}
      getOptionLabel={option => option.name || ''}
      renderOption={({ name, address }) => (
        <div>
          <div>{name}</div>
          <div className={classes.address}>{address}</div>
        </div>
      )}
      options={data}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label="Pakalpojuma sniedzējs"
          variant="standard"
          fullWidth
        />
      )}
      noOptionsText="Nav atbilstošu ierakstu"
    />
  )
}
