import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SnackbarSuccess from '../../elements/SnackbarSuccess'
import { TagsAutocomplete } from '../../elements/TagsAutocomplete'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  LIST_MERCHANT_TYPES_QUERY,
  LIST_TAGS_QUERY,
} from '../../../gql/Queries'
import Loader from '../../elements/Loader'
import {
  ADD_TAG_MUTATION,
  REMOVE_TAG_MUTATION,
  SET_WALLET_MUTATION,
} from '../../../gql/Mutations'
import red from '@material-ui/core/colors/red'
import { useAuth } from '../../providers/AuthProvider'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import ConfirmModal from '../../elements/ConfirmModal'
import ScheduledWalletUpdatesSection from './scheduled-wallet-updates-section/ScheduledWalletUpdates'
import {
  getCombinedWallets,
  getSelectedWallets,
} from '../../../utils/walletUtils'
import { SnackBarError } from '../../elements/SnackBarError'

const useStyles = makeStyles(theme => ({
  button: {
    width: 360,
  },
  tagsHelperText: {
    fontSize: 10,
    color: red[600],
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkBoxWrapper: {
    display: 'block',
  },
  fieldset: {
    borderColor: '#ffad00',
  },
  error: {
    fontSize: 14,
    color: red[700],
    marginTop: 8,
    whiteSpace: 'pre-wrap',
  },
}))

export default function Settings() {
  const { tenantId } = useAuth()
  const { loading, data, error } = useQuery(LIST_MERCHANT_TYPES_QUERY, {
    variables: {
      tenant: tenantId,
      tenantLong: tenantId,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <>Kļūda iegūstot datus</>
  }

  const combinedWallets = getCombinedWallets(
    data?.getTenantById?.merchantTypes,
    data?.definedWallets?.byTenant,
  )

  return (
    <>
      <ScheduledWalletUpdatesSection walletTypes={combinedWallets} />
      <ClearWalletsSection walletTypes={combinedWallets} />
      <TagsSection />
    </>
  )
}

export function ClearWalletsSection({ walletTypes }) {
  const { tenantId } = useAuth()
  const { refetch } = useQuery(SET_WALLET_MUTATION, {
    skip: true,
  })

  const [formState, setFormState] = React.useState({
    wallets: walletTypes.map(w => ({ ...w, checked: false })),
    tags: [],
  })
  const [formErrors, setFormErrors] = React.useState({})
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false)
  const [showConfirmCleanWalletsModal, setShowConfirmCleanWalletsModal] =
    React.useState(false)
  const classes = useStyles()

  const onCheckboxChange = (checked, index) => {
    setFormErrors({})
    const updatedWallets = [...formState.wallets]
    updatedWallets[index].checked = checked
    setFormState({ ...formState, wallets: updatedWallets })
  }

  const onSubmit = () => {
    setFormErrors({})
    setShowConfirmCleanWalletsModal(false)

    const selectedWallets = getSelectedWallets(formState.wallets)

    refetch({
      tenant: tenantId,
      profileIds: [],
      merchantTypes: selectedWallets.merchantTypes,
      definedWallets: selectedWallets.definedWallets,
      tags: formState.tags,
      amount: 0,
      createWallet: false,
    })
      .then(res => {
        if (res.data) {
          setFormState({
            tags: [],
            wallets: formState.wallets.map(w => ({ ...w, checked: false })),
          })
          setShowSuccessMsg(true)
        } else {
          setFormErrors({
            message: 'Kļūda. Mēģiniet vēlreiz.',
          })
        }
      })
      .catch(err => {
        setFormErrors({
          message: 'Kļūda. Mēģiniet vēlreiz.',
        })
      })
  }

  return (
    <>
      <SnackbarSuccess
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      />
      {showConfirmCleanWalletsModal && (
        <ConfirmModal
          onAccept={onSubmit}
          text="Vai tiešām vēlies dzēst atzīmēto maciņu saturu?"
          onCancel={() => setShowConfirmCleanWalletsModal(false)}
          onClose={() => setShowConfirmCleanWalletsModal(false)}
        />
      )}
      <Box mt={4}>
        <fieldset className={classes.fieldset}>
          <legend>Dzēst visu maciņu atlikumus</legend>
          <Box my={2}>
            {formState.wallets.map(({ name, description, checked }, index) => (
              <FormControlLabel
                key={name}
                className={classes.checkBoxWrapper}
                control={
                  <Checkbox
                    checked={checked}
                    color="primary"
                    className={classes.checkbox}
                    disableRipple
                    onChange={(_, _checked) =>
                      onCheckboxChange(_checked, index)
                    }
                  />
                }
                label={description}
              />
            ))}
            <Box className={classes.tag} width={360}>
              <TagsAutocomplete
                onChange={(event, value) =>
                  setFormState({ ...formState, tags: value })
                }
                value={formState.tags}
              />
              <div className={classes.tagsHelperText}>
                *ja netiks izvēlēta neviena atzīme vai maciņa tips, atlikumi
                tiks notīrīti visiem lietotājiem.
              </div>
            </Box>
            {formErrors && (
              <div className={classes.error}>
                {Object.values(formErrors).join('\n')}
              </div>
            )}
            <Box mt={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  if (formState.wallets.filter(x => x.checked).length === 0) {
                    setFormErrors({ message: 'Nav atzīmēts neviens maciņš' })
                  } else {
                    setShowConfirmCleanWalletsModal(true)
                  }
                }}
                color="primary"
              >
                Dzēst
              </Button>
            </Box>
          </Box>
        </fieldset>
      </Box>
    </>
  )
}

export function EnableDisableWalletTypesSection({ walletTypes }) {
  const classes = useStyles()
  const [formState, setFormState] = React.useState({
    wallets: walletTypes,
  })
  const [, setFormErrors] = React.useState({})
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false)

  const onCheckboxChange = (checked, index) => {
    const updatedWallets = [...formState.wallets]
    updatedWallets[index].excludedFromAccounting = !checked
    setFormState({ ...formState, wallets: updatedWallets })
  }

  const isFormValid = () => {
    const _errors = []

    //...
    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    //mutation...
    console.log(formState.wallets)
    setShowSuccessMsg(true)
  }

  return (
    <>
      <SnackbarSuccess
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      />
      <Box my={4}>
        <fieldset className={classes.fieldset}>
          <legend>Iespējo/Atspējo lietotājiem pieejamos maciņu veidus</legend>
          <Box my={2}>
            {formState.wallets.map(
              ({ name, description, excludedFromAccounting }, index) => (
                <FormControlLabel
                  key={name}
                  className={classes.checkBoxWrapper}
                  control={
                    <Checkbox
                      checked={!excludedFromAccounting}
                      color="primary"
                      className={classes.checkbox}
                      disableRipple
                      onChange={(_, _checked) =>
                        onCheckboxChange(_checked, index)
                      }
                    />
                  }
                  label={description}
                />
              ),
            )}

            <Box mt={3}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                Saglabāt
              </Button>
            </Box>
          </Box>
        </fieldset>
      </Box>
    </>
  )
}

export function TagsSection() {
  const classes = useStyles()
  const { tenantId } = useAuth()
  const [formErrors, setFormErrors] = React.useState({})
  const [tagText, setTagText] = React.useState('')
  const [tagToDelete, setTagToDelete] = React.useState(null)
  const [showConfirmRemoveTagModal, setShowConfirmRemoveTagModal] =
    React.useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState(null)
  const [addTag] = useMutation(ADD_TAG_MUTATION)
  const [removeTag] = useMutation(REMOVE_TAG_MUTATION)
  const { data: tagsData, refetch: refetchTags } = useQuery(LIST_TAGS_QUERY, {
    variables: {
      tenantId,
    },
  })
  const tags = tagsData?.tenantTags?.listTags || []

  const isFormValid = () => {
    const _errors = []

    if (!tagText.trim()) _errors['tagText'] = 'Šis lauks ir obligāts'

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onAddTag = () => {
    if (!isFormValid()) return

    addTag({
      variables: {
        input: {
          tag: tagText,
          tenantId,
        },
      },
    })
      .then(success => {
        if (success) {
          setShowSuccessMsg(true)
          refetchTags()
        }
      })
      .catch(error => {
        const isDuplicateTag = error.message.includes('Duplicate entry')
        if (isDuplicateTag) {
          setErrorMsg(`Kļūda. Tags "${tagText.trim()}" jau eksistē`)
        } else {
          setErrorMsg('Kļūda. Neizdevās pievienot tagu.')
        }
      })
      .finally(() => {
        setTagText('')
      })
  }

  const onDeleteTag = () => {
    removeTag({
      variables: {
        input: {
          tag: tagToDelete,
          tenantId,
        },
      },
    })
      .then(success => {
        if (success) {
          setShowSuccessMsg(true)
          refetchTags()
        }
      })
      .catch(e => {
        const detailedErrorMsg = e.graphQLErrors[0]?.extensions?.message || ''
        if (
          detailedErrorMsg.includes(
            'This tag of of this tenant is used in a policy',
          )
        ) {
          setErrorMsg(
            'Neizdevās izdzēst tagu, jo tas ir piesaistīts aktīvam maciņa papildināšanas noteikumam',
          )
        } else {
          setErrorMsg('Kļūda. Neizdevās izdzēst tagu')
        }
      })
      .finally(() => {
        setTagToDelete(null)
        setShowConfirmRemoveTagModal(false)
      })
  }
  return (
    <>
      <SnackbarSuccess
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      />
      <SnackBarError
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)}
        message={errorMsg}
      />
      {showConfirmRemoveTagModal && (
        <ConfirmModal
          onAccept={onDeleteTag}
          text="Vai tiešām vēlaties dzēst šo tagu/atzīmi?"
          smallText="*Tags/atzīme tiks dzēsta no visiem lietotāju <br/> profiliem, kuriem tā bija piesaistīta"
          onCancel={() => setShowConfirmRemoveTagModal(false)}
          onClose={() => setShowConfirmRemoveTagModal(false)}
        />
      )}
      <Box my={4}>
        <fieldset className={classes.fieldset}>
          <legend>Tagi</legend>
          <Box width={360}>
            <Box my={2}>
              <TextField
                variant="outlined"
                fullWidth
                label="Tagi"
                size="small"
                value={tagText}
                onChange={({ target: { value } }) => setTagText(value)}
                error={Boolean(formErrors.tagText)}
                helperText={formErrors.tagText}
              />
              <Box mt={2}>
                {tags.map(t => (
                  <Chip
                    style={{ marginTop: 4, marginRight: 2 }}
                    key={t}
                    label={t}
                    onDelete={() => {
                      setTagToDelete(t)
                      setShowConfirmRemoveTagModal(true)
                    }}
                  />
                ))}
              </Box>
              <Box mt={3}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={onAddTag}
                  color="primary"
                >
                  Saglabāt
                </Button>
              </Box>
            </Box>
          </Box>
        </fieldset>
      </Box>
    </>
  )
}
