import React, { useState, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { debounce } from '../../utils/common'

export const SearchInput = ({ onChangeHandler, label, width = 300 }) => {
  const [searchText, setSearchText] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateMultiContains = useCallback(
    debounce(val => onChangeHandler(val.trim()), 300),
    [],
  )

  const onSearchInputChange = event => {
    const val = event.target.value

    setSearchText(val)
    updateMultiContains(val)
  }

  return (
    <TextField
      variant="standard"
      label={label}
      style={{ width }}
      value={searchText}
      onChange={onSearchInputChange}
      InputProps={{
        endAdornment: (
          <IconButton disabled>
            <InputAdornment position="end">
              <SearchIcon onClick={null} />
            </InputAdornment>
          </IconButton>
        ),
      }}
    />
  )
}
