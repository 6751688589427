import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'
import ProfileModal from '../views/ProfileModal'
import { useAuth } from '../providers/AuthProvider'

const logoPath = `${process.env.PUBLIC_URL}/assets/images/labumi-logo.png`

const useStyles = makeStyles(theme => ({
  logoutBox: {
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
}))

export default function Header() {
  const classes = useStyles()
  const [openProfileModal, setOpenProfileModal] = React.useState(false)
  const history = useHistory()
  const { firstName, familyName, logout } = useAuth()

  const onLogoutClick = () => {
    logout()
    history.replace('/')
  }

  return (
    <>
      {openProfileModal && (
        <ProfileModal onClose={() => setOpenProfileModal(false)} />
      )}
      <Box
        my={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box ml={0.3}>
          <Link to="/">
            <img alt="logo" src={logoPath} height={60} />
          </Link>
        </Box>
        <Box
          className={classes.logoutBox}
          display="flex"
          alignItems="center"
          mr={1}
        >
          <Box
            title="Atvērt profila iestatījumus"
            color="inherit"
            onClick={() => setOpenProfileModal(true)}
            display="flex"
            alignItems="center"
          >
            <PersonIcon />{' '}
            <span style={{ fontSize: 14 }}>
              {firstName} {familyName}
            </span>
          </Box>
          <ExitToAppIcon onClick={onLogoutClick} style={{ marginLeft: 8 }} />
        </Box>
      </Box>
    </>
  )
}
