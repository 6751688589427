import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import classNames from 'classnames'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import orange from '@material-ui/core/colors/orange'
import { isValidNumberInput } from '../../../../utils/validation'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
    width: 360,
  },
  button: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  tagsHelperText: {
    fontSize: 10,
  },
  checkbox: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogActions: {
    '&.MuiDialogActions-root': {
      justifyContent: 'center',
      marginTop: 8,
      padding: '0 24px',
    },
  },
  amount: {
    padding: '12px 0',
    width: 80,
    textAlign: 'center',
    backgroundColor: orange[50],
    borderRadius: '4px',
    marginRight: 8,
    userSelect: 'none',
    cursor: 'pointer',
    opacity: 0.9,
  },
  amountSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    opacity: 1,
  },
  input: {
    padding: '12px 0',
    width: 80,
    border: 'none',
    textAlign: 'center',
    backgroundColor: orange[50],
    fontSize: 18,
    opacity: 0.9,
    borderRadius: '4px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      opacity: 1,
    },
    '&:focus::placeholder': {
      color: '#FFF',
    },
  },
  user: {
    marginLeft: 24,
  },
}))

export const TopupWalletByProfileAndWalletTypeModal = ({
  profile,
  onSuccess,
  onClose,
  walletType,
}) => {
  const classes = useStyles()
  const amounts = [5, 10, 40]
  //form data
  const [formState, setFormState] = React.useState({
    otherAmount: '',
    selectedAmount: '',
  })
  const [, setFormErrors] = React.useState({})

  const onFieldChange = ({ target }) => {
    const editedValue = target.value.replace(',', '.').trim()
    if (!isValidNumberInput(editedValue)) {
      return
    }
    setFormState(prevState => ({ ...prevState, [target.name]: editedValue }))
  }

  const onSelectAmount = amount => {
    setFormState({ ...formState, selectedAmount: amount, otherAmount: '' })
  }

  const isFormValid = () => {
    // const {otherAmount, selectedAmount}= formState;
    const _errors = []

    //...
    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    //.....
    onSuccess(Number(formState.selectedAmount || formState.otherAmount))
  }

  const onEnterPress = ev => {
    if (ev.key === 'Enter') onSubmit()
  }

  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle
            className={classes.dialogTitle}
          >{`Papildināt ${walletType.toLowerCase()} maciņa atlikumu`}</DialogTitle>
          {profile.firstName && (
            <div className={classes.user}>
              Lietotājs: {`${profile.firstName} ${profile.familyName}`}
            </div>
          )}
          <DialogContent>
            <Box mt={1} display="flex" justifyContent="space-between">
              {amounts.map(_amount => (
                <div
                  className={classNames(classes.amount, {
                    [classes.amountSelected]:
                      _amount === formState.selectedAmount,
                  })}
                  key={_amount}
                  onClick={() => onSelectAmount(_amount)}
                >{`${_amount}€`}</div>
              ))}
              <input
                className={classes.input}
                onFocus={() =>
                  setFormState({
                    ...formState,
                    selectedAmount: '',
                  })
                }
                onChange={onFieldChange}
                onKeyUp={onEnterPress}
                name="otherAmount"
                placeholder="Cits"
                value={formState.otherAmount}
                autoFocus
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              fullWidth
              className={classes.button}
              variant="contained"
              onClick={onSubmit}
              color="primary"
            >
              Papildināt
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
