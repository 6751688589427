import React from 'react'
import {
  createMuiTheme,
  ThemeProvider as Provider,
} from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#f4822e',
      contrastText: '#ffffff',
    },
  },
  status: {
    danger: 'orange',
  },
})

export default function ThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}
