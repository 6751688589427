import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import PeopleIcon from '@material-ui/icons/People'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SettingsIcon from '@material-ui/icons/Settings'
import DescriptionIcon from '@material-ui/icons/Description'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.background.paper,
  },
  homeIcon: {
    width: 34,
    height: 34,
  },
  tab: {
    '&.MuiTab-labelIcon': {
      minHeight: 0,
    },
  },
}))

export default function Navigation() {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  return (
    <Box alignItems="center" display="flex">
      <AppBar position="static" color="primary">
        <Tabs
          value={location.pathname}
          onChange={(_, selectedPath) => history.push(selectedPath)}
          indicatorColor="secondary"
          variant="fullWidth"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            className={classes.tab}
            label="Lietotāji un maciņi"
            value="/"
            icon={<PeopleIcon />}
          />
          <Tab
            className={classes.tab}
            label="Darījumi"
            value="/darijumi"
            icon={<MenuBookIcon />}
          />
          <Tab
            className={classes.tab}
            label="Dokumenti"
            value="/dokumenti"
            icon={<DescriptionIcon />}
          />
          <Tab
            className={classes.tab}
            label="Iestatījumi"
            value="/iestatijumi"
            icon={<SettingsIcon />}
          />
        </Tabs>
      </AppBar>
    </Box>
  )
}
