import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export function CompaniesAutocomplete({
  variant = 'outlined',
  companies,
  ...props
}) {
  return companies.length > 1 ? (
    <Autocomplete
      {...props}
      multiple
      options={companies}
      getOptionLabel={option => option.name}
      filterSelectedOptions
      size="small"
      renderInput={params => (
        <TextField
          margin="dense"
          {...params}
          label="Uzņēmumi"
          variant={variant}
          fullWidth
        />
      )}
      noOptionsText="Nav atbilstošu ierakstu"
    />
  ) : null
}
