import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  button: {
    minWidth: 90,
  },
  smallText: {
    fontSize: 10,
    color: grey[700],
  },
})

export default function ConfirmModal({
  onClose,
  onCancel,
  onAccept,
  text,
  smallText,
}) {
  const classes = useStyles()

  return (
    <Dialog open>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogContent>
            <Box>
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {smallText && (
                <div
                  className={classes.smallText}
                  dangerouslySetInnerHTML={{ __html: smallText }}
                />
              )}
            </Box>
            <Box mt={3} mb={1} display="flex" justifyContent="center">
              <Button
                className={classes.button}
                variant="contained"
                onClick={onCancel}
                color="primary"
              >
                Nē
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                className={classes.button}
                variant="contained"
                onClick={onAccept}
                color="primary"
              >
                Jā
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  )
}
